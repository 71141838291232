<template>
    <div class="card-container">
        <v-card class="info-card">
            <template v-if="isLoading">
                <div class="loading-container">
                    <v-icon large color="grey darken-1" class="loading-spinner">mdi-loading</v-icon>
                    <p>Cargando datos...</p>
                </div>
            </template>
            <template v-else>
                <v-btn icon x-small class="sync-button" @click.stop="handleSyncClick">
                    <v-icon :class="{ 'rotate-icon': isSyncing }">mdi-sync-circle</v-icon>
                </v-btn>
                <div class="card-content" :class="{ flipped: flipped }">
                    <div class="card-front card-face">
                        <v-row align="center" no-gutters>
                            <v-col cols="4">
                                <div class="icon-background">
                                    <v-icon xx-large :color="iconColor" class="icon-small">
                                        mdi-network
                                    </v-icon>

                                </div>
                            </v-col>
                            <v-col cols="8">
                                <div class="title-container">
                                    <div class="info-title">{{ title }}</div>
                                </div>
                                <div>
                                    <div class="info-value">
                                        <span class="text-value">{{ valves.length }}</span>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="card-back card-face">
                        <div v-if="title === 'Válvulas abiertas'">
                            <div v-if="valves && valves.length" class="chip-container">
                                <v-chip v-for="(valve, index) in visibleValves" :key="index" class="ma-1"
                                    color="teal darken-2" outlined>
                                    {{ valve }}
                                </v-chip>
                                <v-btn v-if="valves.length > maxVisible" @click.stop="showMore" class="mt-2" small text
                                    color="blue darken-4">
                                    {{ showAll ? 'Ver menos' : 'Ver más' }}
                                </v-btn>
                            </div>
                            <div v-else>
                                <div class="mt-5">No hay válvulas conectadas</div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        valves: {
            type: Array,
            default: () => [] // Aseguramos un valor por defecto
        },
    },
    data() {
        return {
            titleClass: '',
            flipped: false,
            showAll: false,
            isSyncing: false,
            isLoading: true, // Estado para controlar el loader
            maxVisible: 10,
            title: 'Válvulas abiertas',
        };
    },
    computed: {
        visibleValves() {
            return this.showAll ? this.valves : this.valves.slice(0, this.maxVisible);
        },
        iconColor() {
            // Si el número de válvulas es mayor a 0, se pone el icono en verde
            return this.valves.length > 0 ? 'teal darken-2' : 'blue-grey lighten-3';
        },
    },
    methods: {
        flipCard() {
            this.flipped = !this.flipped;
        },
        handleSyncClick() {
            this.isSyncing = true;
            this.flipCard();
            setTimeout(() => {
                this.isSyncing = false;
            }, 2000);
        },
        checkDataLoaded() {
            // Desactiva el loader si las válvulas están disponibles
            if (this.valves && this.valves.length >= 0) {
                this.isLoading = false;
            }
        },
    },
    mounted() {
        // Simula el proceso de carga inicial
        setTimeout(() => {
            this.checkDataLoaded();
        }, 1000); // Simula un retraso para mostrar el loader
    },
    watch: {
        // Si las props cambian, verifica si los datos están listos
        valves() {
            this.checkDataLoaded();
        },
    },
};

</script>

<style scoped>
.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.small-text {
    font-size: 15px;
}

.sync-button {
    color: gray;
}

.sync-button:hover {
    color: #00acc1;
}

.text-right {
    text-align: right;
    margin-right: 10%;
}

.text-value {
    font-size: 55px;
    font-weight: 450;
}

.card-container {
    perspective: 1000px;
    width: 100%;
    position: relative;
}

.info-card {
    width: 100%;
    height: 145px;
    padding: 30px;
    background-color: white;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.3s ease-in-out;
    position: relative;
}

.card-content {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-face {
    backface-visibility: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.card-front {
    transform: rotateY(0deg);
}

.card-back {
    transform: rotateY(180deg);
    position: absolute;
    /* top: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.flipped {
    transform: rotateY(180deg);
}

.icon-xx-large {
    font-size: 70px;
    color: #00796d;
}

.icon-small {
    font-size: 60px;
}

.icon-background {
    background-color: #e6f0eb;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-container {
    text-align: right;
    height: 100%;
}

.info-title {
    margin-right: 12%;
    font-weight: 450;
    color: #333;
    font-size: 18px;
}

.align-title {
    margin-top: -20px;
}

.align-ecos-core {
    margin-top: -12px;
}

.info-value {
    font-size: 18px;
    margin-right: 10%;
    text-align: right;
}

.info-value-core {
    font-size: 16px;
    margin-right: 10%;
    text-align: right;
    margin-top: 10%
}

.text-value-core {
    margin-left: 8px;
    font-size: 30px;
    font-weight: 450;
}

.forecast-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.forecast-item {
    text-align: center;
}

.icon-photo {
    width: 75px;
}

.chip-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.subtitle-1 {
    color: #666;
    font-size: 14px;
    text-align: center;
}

.sync-button {
    position: absolute;
    top: 8px;
    right: 8px;
}

.rotate-icon {
    animation: spin 0.6s linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

:root {
    --status-indicator-size: 15px;
    --status-indicator-animation-duration: 2s;

    --status-indicator-color-positive: rgb(75, 210, 143);
    --status-indicator-color-positive-semi: rgba(75, 210, 143, .5);
    --status-indicator-color-positive-transparent: rgba(75, 210, 143, 0);
}

@keyframes status-indicator-pulse-positive {
    0% {
        box-shadow: 0 0 0 0 var(--status-indicator-color-positive-semi);
    }

    70% {
        box-shadow: 0 0 0 var(--status-indicator-size) var(--status-indicator-color-positive-transparent);
    }

    100% {
        box-shadow: 0 0 0 0 var(--status-indicator-color-positive-transparent);
    }
}

.status-indicator {
    display: inline-block;
    border-radius: 50%;
    width: var(--status-indicator-size);
    height: var(--status-indicator-size);
    background-color: var(--status-indicator-color-positive);
}

.status-indicator[pulse] {
    animation: status-indicator-pulse-positive var(--status-indicator-animation-duration) ease-in-out infinite;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
}

.loading-spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>