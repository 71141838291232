<template>
  <div>
  <v-app-bar color="rgb(57, 109, 98)" dark app clipped-left src="../assets/bg.png">
    <v-app-bar-nav-icon @click="changeMini" v-if="!$vuetify.breakpoint.mobile">
    </v-app-bar-nav-icon>
    <v-img src="../assets/logo.png" height="40" max-width="80"></v-img>
    <v-toolbar-title class="ml-5 d-none d-sm-block kozuka-b notranslate">Smart<span class="kozuka-l">Water</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-col md="2" cols="6">
      <v-select v-if="multiFinca" :items="fincas" :value="fincaSelected" item-text="nombre" item-value="dispositivos"
        dense outlined flat class="mt-6 pb-0" @change="changeFinca($event)">
      </v-select>
      <v-container v-else class="d-flex justify-end">
        {{ fincas[0].nombre }}
      </v-container>
    </v-col>
    <v-menu v-model="menu" :nudge-width="200" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab text v-bind="attrs" v-on="on" :class="$vuetify.breakpoint.mobile ? 'mr-0' : 'mr-4'">
          <v-avatar color="teal" size="50" style="cursor: pointer">
            <img v-if="avatar_image" :src="avatar_image" alt="Imagen seleccionada" />
            <span v-else>{{ avatar }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <!-- Contenido del menú -->
      <v-card @mouseleave="cerrarMenu">
        <div class="d-flex justify-end pa-2">
          <v-btn text fab x-small left @click="editar">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center justify-center">
          <div class="d-flex flex-column align-center">
            <v-avatar color="teal" size="85" style="cursor: pointer" @click="editar">
              <img v-if="avatar_image" :src="avatar_image" alt="Imagen seleccionada" />
              <span v-else style="color:white; font-size: 2rem;">{{ avatar }}</span>
            </v-avatar>
            <span class="mt-2">{{ name_user }}</span>
            <span class="text-caption">{{ email }}</span>
          </div>
        </div>
        <v-divider class="ma-3"></v-divider>
        <div class="d-flex justify-space-around pb-3">
          <v-list>
            <v-list-item class="d-flex align-center mb-2" v-if="hasInstalaciones">
              <v-btn 
                color="light-blue darken-4" 
                fab 
                dark 
                small 
                class="mr-2"
                @click="goInstalacion">
                <v-icon dark>mdi-rotate-right</v-icon>
              </v-btn>

              <v-list-item-content>
                <v-list-item-subtitle>Volver a instalaciones</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="d-flex align-center">
              <v-btn 
                color="red darken-2" 
                fab 
                dark 
                small 
                class="mr-2"
                @click="logout">
                <v-icon dark>mdi-exit-to-app</v-icon>
              </v-btn>
              <v-list-item-content>
                <v-list-item-subtitle>Cerrar sesión</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-card>
    </v-menu>
  </v-app-bar>
  <div v-if="loading" class="loading-overlay">
      <div class="logo-image-loading-container">
        <v-img :src="icon" class="logo-image-loading"></v-img>
      </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import icon from "@/assets/icon.png";
export default {
  name: "AppBar",
  data() {
    return {
      status: false,
      battery: 10,
      hover: false,
      menu: false,
      avatar_image: null,
      name_user: '',
      lastname_user: '',
      loading: false,
      email: localStorage.getItem("email"),
      icon: icon,
      fincas: JSON.parse(localStorage.getItem('fincas'))
    };
  },
  methods: {
    changeMini: function () {
      this.$emit("changeMini");
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    goInstalacion() {
     this.loading = true;

      // Limpiar del localStorage las fincas y dispositivos
      localStorage.removeItem("fincas");
      localStorage.removeItem("dispositivos");
      localStorage.removeItem("id_finca");

      // Redirigir a la página de instalación después de 1 segundo (simulación de carga)
      setTimeout(() => {
        this.$router.push("/Instalacion");
        this.loading = false;
      }, 1000);
    },
    editar() {
      // Verifica si la ruta actual es diferente de "/Usuario" para redireccionar
      if (this.$route.path !== "/Usuario") {
        this.$router.push("/Usuario");
        this.menu = false;
      } // Si la ruta actual es "/Usuario" vuelve a cargar la página
      else {
        this.$router.go();
      }
    },
    changeFinca(id_dispositivo) {
  
      this.$emit("changeFinca", id_dispositivo)
    },
    handleAvatarUpdated(url_avatar) {
      this.avatar_image = url_avatar;
    },
    handleNameUpdated(name) {
      this.name_user = name;
    },
    handleLastnameUpdated(lastname) {
      this.lastname_user = lastname;
    },
    cerrarMenu() {
      this.menu = false;
    },
    // Logica para obtener datos del usuario
    getUserInfo() {
      const id = localStorage.getItem("id");
      axios
        .post(process.env.VUE_APP_API_URL + "/getUserInfo", {
          id: id,
        })
        .then(res => {
          this.name_user = res.data.nombre;
          this.lastname_user = res.data.apellido;
          this.avatar_image = res.data.URL_avatar;
        })
        .catch((error) => {
          console.error('Error al obtener los datos del usuario:', error);
        });
    },
  },

  computed: {
    avatar() {
      const nombre = localStorage.getItem("nombre");
      const apellido = localStorage.getItem("apellido");
      return nombre[0] + apellido[0];
    },
    // Logica para obtener la finca seleccionada
    fincaSelected() {
      // Obtiene el valor de la finca seleccionada
      const dispositivos = localStorage.getItem("dispositivos");
      
      // Verifica si la finca seleccionada es diferente de null
      try {
        // Busca la finca seleccionada en el array de fincas con el valor de dispositivos como referencia y también su id
        const finca = this.fincas.find(finca => (finca.dispositivos).replace(" ", "") === dispositivos)
        // Retorna la finca seleccionada
        return finca
      }
      catch {
        // Si no encuentra la finca seleccionada, retorna la primera finca
        return this.fincas[0];
      }
    },
    multiFinca() {
      try {
        if (this.fincas.length > 1) {
          return true
        } else {
          return false;
        }
      }
      catch {
        return false;
      }
    },
    // Verificamos si hay instalaciones en el localStorage
    hasInstalaciones() {
      const instalaciones = JSON.parse(localStorage.getItem('instalaciones') || '[]');
      return instalaciones.length > 0;
    },
  },
  mounted() {
    this.getUserInfo();
    this.$root.$on("update-avatar", this.handleAvatarUpdated);
    this.$root.$on("update-nombre", this.handleNameUpdated);
    this.$root.$on("update-apellido", this.handleLastnameUpdated);
  },
};
</script>

<style>
@import url(../styles/fonts.css);
</style>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.965);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.logo-image-loading-container {
  width: 80px; 
  height: 90px; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-image-loading {
  max-width: 80%; 
  max-height: 80%; 
  opacity: 0.9;
  animation: fade-in-out 1s infinite;
}
</style>