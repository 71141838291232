<template>
  <div id="dashboard-map">
    <div style="position: absolute; top:50px; left: 10px; z-index: 100;" class="mt-11">
      <v-menu v-model="show_menu" style="position: absolute;" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn x-small style="height: 40px;" color="white" v-on="on" @mouseenter="changeIconColor('grey darken-3')"
            @mouseleave="changeIconColor('grey darken-1')">
            <v-icon :color="icon_color">mdi-filter</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-2">
          <v-checkbox v-model="show_sensors" label="Sensores" @change="updateMarkers"></v-checkbox>
          <v-checkbox v-model="show_valves" label="Válvulas" @change="updateMarkers"></v-checkbox>
        </v-list>
      </v-menu>
    </div>
    <GmapMap ref="gmap" :center="centerMap" :zoom="map_zoom" map-type-id="satellite" class="w-100"
      :style="$vuetify.breakpoint.lgAndDown ? 'height:510px;' : 'height:730px'" :options="{ disableDefaultUI: false }"
      @loaded="onMapLoaded" v-if="isLoaded">
      <GmapMarker v-for="(m, index) in filteredMarkers" :key="index" :position="m.position" :clickable="true"
        :draggable="false" :label="m.label" :icon="m.icon" :type="m.type" @click="$emit('emitIdValve', m)"
        :opacity="0.9" />
    </GmapMap>
  </div>
</template>

<script>
import valve_closed from "@/assets/valve_closed.png";
import valve_opened from "@/assets/valve_opened.png";
import sensor_humedad from "@/assets/sensor_humedad.png";
import sensor_ph from "@/assets/sensor_ph.png";
import sensor_temperatura from "@/assets/sensor_temperatura.png";
import sensor_conductividad from "@/assets/sensor_conductividad.png";
import sensor_default from "@/assets/sensor_default.png";

export default {
  props: {
    valvulas: {
      type: Array,
      default: () => [], // Asegura que valvulas no sea undefined
    },
    sensores: {
      type: Array,
      default: () => [], // Asegura que sensores no sea undefined
    },
  },
  data() {
    let isLoaded = false;
    let valvulasAbiertas = [];

    if (this.valvulas[0].latitud !== null) {
      isLoaded = true;
    }
    this.valvulas.map((el) => {
      if (el.current_state > 1) {
        valvulasAbiertas.push(el);
      }
    });
    return {
      centerMap: {
        lat: 0,
        lng: 0
      },
      isLoaded,
      markers: [],
      valvulasAbiertas,
      show_menu: false,
      show_sensors: true,
      show_valves: true,
      icon_color: 'grey darken-1',
      map_zoom: 15,
      currentLocation: null,
      userLocationMarker: null,
      userLocationCircle: null,
      previousPolygon: null,
    };
  },
  watch: {
    valvulas: function (newVal) {
      let newValvulasAbiertas = [];
      newVal.map((el) => {
        if (el.current_state >= 1) {
          newValvulasAbiertas.push(el);
        }
      });

      if (JSON.stringify(newValvulasAbiertas) != JSON.stringify(this.valvulasAbiertas)) {
        this.markers = [];
        newVal.map((el) => {
          this.markers.push({
            position: {
              lat: parseFloat(el.latitud),
              lng: parseFloat(el.longitud),
            },
            label: {
              text: el.nombre,
              color: "white",
            },
            icon: el.current_state > 0 ? valve_opened : valve_closed,
            data: el,
            type: "valvula",
          });
        });
        this.sensores.map((el) => {
          let sensorIcon;
          switch (el.id_tipo_sensor) {
            case 18:
              sensorIcon = sensor_ph;
              break;
            case 16:
              sensorIcon = sensor_humedad;
              break;
            case 17:
              sensorIcon = sensor_temperatura;
              break;
            case 20:
              sensorIcon = sensor_conductividad;
              break;
            default:
              sensorIcon = sensor_default;
              break;
          }
          this.markers.push({
            position: {
              lat: parseFloat(el.latitud),
              lng: parseFloat(el.longitud),
            },
            label: {
              text: el.nombre === '' ? "Sensor" : el.nombre,
              color: "white",
            },
            icon: sensorIcon,
            data: el,
            type: "sensor",
          });
        });
      }
      this.valvulasAbiertas = newValvulasAbiertas;
    },
  },
  computed: {
    filteredMarkers() {
      if (this.show_sensors && this.show_valves) {
        return this.markers;
      } else if (this.show_sensors) {
        return this.markers.filter((marker) => marker.type === "sensor");
      } else if (this.show_valves) {
        return this.markers.filter((marker) => marker.type === "valvula");
      } else {
        return [];
      }
    },
  },
  /* global google */
  methods: {
    async getCurrentLocation() {
      try {
        const location = await this.getCurrentLocationPromise();
        if (location) {
          this.currentLocation = location;

          if (this.userLocationMarker) {
            this.userLocationMarker.setMap(null);
          }

          if (this.userLocationCircle) {
            this.userLocationCircle.setMap(null);
          }

          this.userLocationMarker = new google.maps.Marker({
            position: location,
            map: this.$refs.gmap.$mapObject,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 10,
              fillColor: '#4285f4',
              fillOpacity: 0.8,
              strokeWeight: 1,
              strokeColor: 'white',
            }
          });

          this.userLocationCircle = new google.maps.Circle({
            center: location,
            map: this.$refs.gmap.$mapObject,
            radius: 20,
            fillColor: '#aec6ed',
            fillOpacity: 0.1,
            strokeColor: '#aec6ed',
            strokeOpacity: 0.4,
            strokeWeight: 1,
          });

          this.watchUserOrientation();
          this.$forceUpdate();
        }
      } catch (error) {
        console.error('Error al obtener la ubicación del usuario: ', error);
      }
    },
    getCurrentLocationPromise() {
      return new Promise((resolve) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            (error) => {
              console.error(error);
              resolve(null);
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            }
          );
        } else {
          console.error("Geolocation no está disponible en este navegador.");
          resolve(null);
        }
      });
    },
    watchUserOrientation() {
      if (window.DeviceOrientationEvent) {
        window.addEventListener("deviceorientation", (event) => {
          if (this.userLocationMarker && event.alpha !== null) {
            const heading = event.alpha;
            this.updateUserOrientation(this.userLocationMarker.getPosition(), heading);
          }
        }, true);
      } else {
        console.error("El dispositivo no soporta la orientación del usuario.");
      }

      this.$refs.gmap.$mapObject.addListener('zoom_changed', () => {
        if (this.userLocationMarker) {
          const heading = this.userOrientation ? this.userOrientation.heading : 0;
          this.updateUserOrientation(this.userLocationMarker.getPosition(), heading);
        }
      });
    },
    updateUserOrientation(position, heading) {
      const angle = heading * (Math.PI / 180);
      const zoom = this.$refs.gmap.$mapObject.getZoom();
      let length = 0.001;

      if (zoom >= 15) {
        length = 0.001 * Math.pow(0.8, zoom - 15);
      }

      const coneAngle = Math.PI / 12;

      if (this.previousPolygon) {
        this.previousPolygon.setMap(null);
      }

      const path = [
        { lat: position.lat(), lng: position.lng() },
        {
          lat: position.lat() + length * Math.cos(angle - coneAngle),
          lng: position.lng() + length * Math.sin(angle - coneAngle),
        },
        {
          lat: position.lat() + length * Math.cos(angle + coneAngle),
          lng: position.lng() + length * Math.sin(angle + coneAngle),
        }
      ];

      const newPolygon = new google.maps.Polygon({
        paths: path,
        map: this.$refs.gmap.$mapObject,
        fillColor: '#448aff',
        fillOpacity: 0.35,
        strokeColor: '#448aff',
        strokeOpacity: 0.25,
        strokeWeight: 1,
      });

      this.previousPolygon = newPolygon;
    },
    computeMapZoom() {
      if (this.valvulas.length === 0 && this.sensores.length === 0) {
        return;
      }

      let minLat = Infinity;
      let maxLat = -Infinity;
      let minLng = Infinity;
      let maxLng = -Infinity;

      for (let i in this.valvulas) {
        if (this.valvulas[i].latitud != 0 && this.valvulas[i].longitud != 0) {
          minLat = Math.min(minLat, parseFloat(this.valvulas[i].latitud));
          maxLat = Math.max(maxLat, parseFloat(this.valvulas[i].latitud));
          minLng = Math.min(minLng, parseFloat(this.valvulas[i].longitud));
          maxLng = Math.max(maxLng, parseFloat(this.valvulas[i].longitud));
        }
      }

      const latDiff = maxLat - minLat;
      const lngDiff = maxLng - minLng;

      const maxDiff = Math.max(latDiff, lngDiff);
      const earthCircumference = 40075;
      const zoomFactor = Math.log2(earthCircumference / (maxDiff * 110.574));

      this.map_zoom = Math.round(zoomFactor - Math.log2(1));
    },
    computedCenterCoordinate() {
      let lat = 0;
      let lng = 0;
      let count = 0;

      for (let i in this.valvulas) {
        if (this.valvulas[i].latitud != 0 && this.valvulas[i].longitud != 0) {
          lat += parseFloat(this.valvulas[i].latitud);
          lng += parseFloat(this.valvulas[i].longitud);
          count++;
        }
      }

      this.centerMap = { lat: lat / count, lng: lng / count };
    },
    changeIconColor(color) {
      this.icon_color = color;
    },
    updateMarkers() {
      this.$nextTick(() => {
        if (this.$refs.gmap) {
          this.googleMarkers = this.$refs.gmap.$mapObject.markers;
          this.setZoomListener();
        }
      });
    },
    setZoomListener() {
      const gmapRef = this.$refs.gmap;

      if (gmapRef && gmapRef.$mapObject) {
        const map = gmapRef.$mapObject;

        google.maps.event.addListener(map, 'zoom_changed', () => {
          const zoomLevel = map.getZoom();
          const baseIconSize = 28;
          const zoomMultiplier = 2.8;

          const adjustedZoomMultiplier = zoomMultiplier + (zoomLevel - 15) * 0.1;

          const newIconSize = zoomLevel <= 15 ? baseIconSize : zoomLevel * adjustedZoomMultiplier;

          this.googleMarkers.forEach((marker) => {
            const icon = {
              url: marker.icon.url,
              scaledSize: new google.maps.Size(newIconSize, newIconSize),
            };

            marker.setIcon(icon);
          });
        });
      } else {
        console.error('Error: $refs.gmap or $refs.gmap.$mapObject is undefined.');
      }
    },
    //Función para obtener los marcadores de las valvulas y sensores en el mapa
    getMarkers() {
      this.valvulas.map((el) => {
        this.markers.push({
          position: {
            lat: parseFloat(el.latitud),
            lng: parseFloat(el.longitud),
          },
          label: {
            text: el.nombre,
            color: "white",
          },
          icon: el.current_state > 0 ? valve_opened : valve_closed,
          data: el,
          type: "valvula",
        });
      });
      this.sensores.map((el) => {
        let sensorIcon;
        switch (el.id_tipo_sensor) {
          case 18:
            sensorIcon = sensor_ph;
            break;
          case 16:
            sensorIcon = sensor_humedad;
            break;
          case 17:
            sensorIcon = sensor_temperatura;
            break;
          case 20:
            sensorIcon = sensor_conductividad;
            break;
          default:
            sensorIcon = sensor_default;
            break;
        }

        this.markers.push({
          position: {
            lat: parseFloat(el.latitud),
            lng: parseFloat(el.longitud),
          },
          label: {
            text: el.nombre === '' ? "Sensor" : el.nombre,
            color: "white",
          },
          icon: sensorIcon,
          data: el,
          type: "sensor",
        });
      });
    },

    onMapLoaded(map) {
      if (map && map.markers) {
        this.googleMarkers = map.markers;
        this.setZoomListener();
      } else {
        console.error("Error: El objeto del mapa o sus marcadores no están disponibles.");
        this.googleMarkers = []; // Asegúrate de que no sea undefined para evitar errores posteriores
      }
    }
  },
  beforeMount() {
    this.getMarkers();
    this.computedCenterCoordinate();
    this.computeMapZoom();

  },
  mounted() {


    // He quitado esto porque daba error y no se si servia para algo
    // this.$nextTick(() => {
    //   if (this.$refs.gmap) {
    //     // Almacena los marcadores en googleMarkers para referenciarlos más tarde
    //     this.googleMarkers = this.$refs.gmap.$mapObject.markers;
    //     this.setZoomListener();
    //   }
    // });

    // this.getCurrentLocation();
    // this.getOrientation();
  },
};
</script>

<style>
.gm-style iframe+div {
  border: none !important;
}
</style>
