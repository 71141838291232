<template>
  <div>
    <v-container class="blue-grey lighten-5 mb-5" fluid>
      <v-row>
        <v-col cols="12 px-0 py-0">
          <v-data-table :headers="headersSecuenciales" :items="programacionesSecuenciales" sort-by="id"
            class="elevation-1 perfil_row mb-5" hide-default-footer :items-per-page="-1" loading-text="Cargando..."
            item-key="id" locale="es-es" :no-data-text="grupos.length > 0
              ? 'No hay programaciones registradas'
              : 'Debes crear un grupo'
              ">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Programaciones</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn fab small color="teal darken-2" @click="openNewProgramacionDialog" v-if="grupos.length > 0">
                  <v-icon color="white"> mdi-plus </v-icon>
                </v-btn>
              </v-toolbar>
            </template>
            <!--Template para acciones: editar y eliminar-->
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItemSecuencial(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItemSecuencial(item)">
                mdi-delete
              </v-icon>
            </template>

            <!-- Template de habilitación -->
            <template v-slot:[`item.is_enabled`]="{ item }">
              <div class="d-flex justify-start">
                <v-switch v-model="item.is_enabled" color="teal darken-2" inset @click="handleSwitchChange(item)" />
              </div>
            </template>

            <template v-slot:[`item.is_activa`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-if="item.is_activa === 0" class="status-indicator" v-bind="attrs" v-on="on"
                    style="background-color: grey;"></span>
                  <span v-else-if="item.is_activa === 1" class="status-indicator" pulse v-bind="attrs" v-on="on"
                    style="background-color: teal;"></span>
                  <span v-else-if="item.is_activa === 2" class="status-indicator" v-bind="attrs" v-on="on"
                    style="background-color: blue;"></span>
                  <span v-else-if="item.is_activa === 3" class="status-indicator" v-bind="attrs" v-on="on"
                    style="background-color: red;"></span>
                </template>
                <span>
                  {{ item.is_activa === 1 ? "Activa" : item.is_activa === 2 ? "En espera" : item.is_activa === 3 ?
                    "Detenida" : "Inactiva" }}
                </span>
              </v-tooltip>
            </template>

            <!-- Template de fecha -->
            <template v-slot:[`item.fecha`]="{ item }">
              {{ item.fecha }}
            </template>

            <!--Template para repetir-->
            <template v-slot:[`item.repetir_cada`]="{ item }">
              <v-chip :color="item.repetir_cada != 0 ? 'teal darken-2' : ''" small dark>
                {{
                  item.repetir_cada != 0
                    ? item.repetir_cada == 1
                      ? item.repetir_cada + " día"
                      : item.repetir_cada + " días"
                    : "No repetir"
                }}
              </v-chip>
            </template>

            <template v-slot:[`item.is_repetir_anual`]="{ item }">
              {{
                item.is_programacion_base == 1
                  ? "Siempre"
                  : item.is_repetir_anual
                    ? "Sí"
                    : "No"
              }}
            </template>

            <template v-slot:[`item.grupo`]="{ item }">
              <v-chip v-for="gr in item.grupo" :key="gr.id">
                {{
                  grupos.find((grupo) => grupo.id === gr.id_configuracion)
                    .nombre
                }}
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <!-- Dialog secuencial -->
    <v-dialog v-model="dialog_secuencial" class="details" :fullscreen="isMobile"
      :transition="isMobile ? 'v-bottom-sheet-transition' : ''" :max-width="isMobile ? '' : '780px'"
      @click:outside="close('dialog_secuencial')" @keydown.esc="close('dialog_secuencial')">
      <v-card>
        <v-card-title>
          <span class="headlines">
            {{ formTitle }}
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="close('dialog_secuencial')"> mdi-close </v-icon>
        </v-card-title>
        <v-row class="ma-4">
          <v-col class="pl-2 mt-n4" cols="12" sm="11" md="12">
            <v-text-field v-model="itemSecuencial.nombre" label="Nombre de programación *" :error-messages="nombreError"
              placeholder="Ej.: Programación nocturna, Programación diurna" rounded outlined>
              <template v-slot:append v-if="itemSecuencial.nombre">
              </template>
            </v-text-field>
          </v-col>

        </v-row>
        <v-stepper v-model="stepper_pag" alt-labels class="elevation-0 mt-n8"
          :class="{ 'v-stepper--vertical': $vuetify.breakpoint.smAndDown }">
          <v-stepper-header class="elevation-0" v-if="!$vuetify.breakpoint.smAndDown" :class="!isMobile ? 'mt-n5' : ''">
            <v-stepper-step :complete="stepper_pag > 1" step="1">
              Secuencia de arranque
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="stepper_pag > 2" step="2">
              Configuración de programación
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="stepper_pag > 3" step="3">
              Configuración de repetición
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4">
              Secuencia de apagado
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items :class="$vuetify.breakpoint.smAndDown ? 'mt-n2' : 'mt-n5'">
            <v-stepper-step :complete="stepper_pag > 1" step="1" v-if="$vuetify.breakpoint.smAndDown">
              <span>Secuencia de arranque </span>
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-card-text class="mt-n6">
                <v-container>
                  <!-- Detalles para secuencia de arranque -->
                  <v-alert text dense color="teal" border="left" icon="mdi-information-variant-circle">
                    <span> ¿Quieres realizar una secuencia de arranque?</span>
                  </v-alert>
                  <v-row class="d-flex justify-center">
                    <v-radio-group v-model="seleccionArranque" row @change="handleSeleccionArranqueChange">
                      <v-radio label="Si" value="si" class="pl-4 mb-1" color="teal darken-2"></v-radio>
                      <v-radio label="No" value="no" class="pl-4 mb-1" color="teal darken-2"></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-row>
                    <v-col cols="auto" class="mr-auto">
                    </v-col>
                    <v-col cols="auto">
                      <v-btn small color="primary" fab @click="goToStep(2)" class="mb-n6"
                        v-if="seleccionArranque === 'no'" :disabled="is_buttton_disabled">
                        <v-icon>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container v-if="seleccionArranque === 'si'" class="mt-n8">
                  <!--Detalles de secuencia encendido-->
                  <v-row>
                    <v-col cols="12" sm="5">
                      <v-select v-model="param_selected" :items="parametros" attach chips label="Maquinarias *" multiple
                        @change="updateSelectedParameters" item-text="descripcion" return-object persistent-hint
                        ref="miSelect" item-disabled="disabled" hint="Selecciona uno o más elementos">

                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon left>mdi-water-sync</v-icon>
                              {{ item.descripcion }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>No hay maquinarias disponibles</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="5" class="d-flex align-end">
                      <v-text-field v-model="retardo_selected.value" :label="`Retardo *`" type="number"
                        hide-spin-buttons hint="Máximo 300 s o 5 min" @input="onRetardoChange" class="custom-height"
                        outlined rounded>
                        <template v-slot:append>
                          <v-select class="mb-4 select-pequeno" v-model="retardo_selected.unit" :items="units" dense
                            hide-details>
                          </v-select>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" class="pa-0 d-flex align-center justify-center"
                      :class="isMobile ? 'mt-n4 mb-2' : ''">
                      <v-btn :class="{ 'mobile-large-btn': isMobile }" style="text-transform: none;"
                        color="teal darken-3" @click="addParam" :disabled="addParamBtn">
                        <span style="color:white">Añadir</span>
                        <v-icon small color="white" right>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="rounded overflow-y-auto mt-3" :class="!isMobile ? 'elevation-2 mt-n4' : ''">
                      <v-data-table height="180px" fixed-header calculate-widths hide-default-footer v-if="!isMobile"
                        no-data-text="No hay parámetro registrado" :headers="headerParametros" :items="editedVariadores"
                        sort-by="orden">
                        <template v-slot:[`item.nombre_parametro`]="{ item }">
                          <v-chip>
                            {{ item.nombre_parametro }}
                          </v-chip>
                        </template>
                        <template v-slot:[`item.retardo`]="{ item }">
                          <v-text-field v-if="item === edit_retardo" v-model="item.retardo" type="number" :max="300"
                            @input="onRetardoChangeEdit(item)" class="custom-height" />
                          <span v-else>{{ formatRetardo(item.retardo) }}</span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon small color="grey" v-if="item === edit_retardo" @click="saveParam(item)"> mdi-check
                          </v-icon>
                          <v-icon small color="grey" v-else @click="editParam(item)"> mdi-pencil-outline </v-icon>
                          <v-icon small color="grey" @click="moveUpParam(item)"> mdi-chevron-up </v-icon>
                          <v-icon small color="grey" @click="moveDownParam(item)"> mdi-chevron-down </v-icon>
                          <v-icon small color="grey" @click="deleteParametro(item)"> mdi-close </v-icon>
                        </template>
                      </v-data-table>
                      <draggable v-else v-model="editedVariadores" :options="{ handle: '.drag-handle' }"
                        @end="onDragEnd" tag="div">
                        <div v-for="(item, index) in editedVariadores" :key="item.id + '-' + index">
                          <v-list-item class="d-flex align-center">
                            <v-list-item-content>
                              <v-list-item-title class="d-flex align-center">
                                <v-icon left class="mdi-drag drag-handle">mdi-drag</v-icon>
                                {{ item.nombre_parametro }}
                              </v-list-item-title>
                              <v-list-item-subtitle>{{ formatRetardo(item.retardo) }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon small color="grey" @click.stop="deleteParametro(item)">mdi-close</v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </div>
                      </draggable>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="auto" class="mr-auto">
                    </v-col>
                    <v-col cols="auto">
                      <v-btn small fab color="primary" @click="goToStep(2)" class="mb-n6"
                        :disabled="!is_param_added || is_buttton_disabled">
                        <v-icon>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-stepper-content>

            <v-stepper-step :complete="stepper_pag > 2" step="2" v-if="$vuetify.breakpoint.smAndDown">
              Configuración de programación
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-card-text class="mt-n6">
                <v-container>
                  <v-row>
                    <!-- Detalles para configuración de programación -->
                    <v-col cols="12" sm="12" md="12">
                      <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                        min-width="290px" @click="menu = true">

                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="itemSecuencial.fecha" label="Fecha de inicio *"
                            prepend-icon="mdi-calendar" rounded :disabled="isDisabled" readonly outlined v-bind="attrs"
                            v-on="on" hide-details=""></v-text-field>
                        </template>
                        <v-date-picker v-model="itemSecuencial.fecha" locale="es-es"
                          :first-day-of-week="1"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                        min-width="290px">

                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="itemSecuencial.hora_inicio" label="Hora de inicio *" outlined rounded
                            :disabled="isDisabled" prepend-icon="mdi-alarm" readonly v-bind="attrs" v-on="on"
                            hide-details=""></v-text-field>
                        </template>
                        <v-time-picker v-model="itemSecuencial.hora_inicio" format="24hr"></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="5" :class="isMobile ? 'mt-n5 mb-2' : 'mt-n4'">
                      <v-select v-model="gruposSelected" :items="grupos" attach chips label="Grupo *" multiple
                        @change="onGrupoChange" persistent-hint hint="Selecciona grupo" item-text="nombre" return-object
                        ref="gruposSelect" item-disabled="disabled">

                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon left>mdi-select-group</v-icon>
                              {{ item.nombre }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="5" class="d-flex align-end">
                      <v-text-field v-model="minutos_selected.value" type="number"
                        prepend-inner-icon="mdi-timer-cog-outline" @input="onMinutosChanged" hide-spin-buttons
                        label="Duración *" class="custom-height" outlined rounded hint="Máximo 1439 min o 23h">
                        <template v-slot:append>
                          <v-select class="mb-4 select-pequeno" v-model="minutos_selected.unit" :items="unitsDuracion"
                            dense hide-details>
                          </v-select>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" class="pa-0 d-flex align-center justify-center"
                      :class="isMobile ? 'mt-n4 mb-2' : ''">
                      <v-btn :class="{ 'mobile-large-btn': isMobile }" style="text-transform: none;"
                        color="teal darken-3" @click="add" :disabled="addGrupoBtn">
                        <span style="color:white">Añadir</span>
                        <v-icon small color="white" right> mdi-plus </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="rounded overflow-y-auto mt-4 pb-1"
                      :class="!isMobile ? 'elevation-2 mt-n3' : ''">
                      <v-data-table height="180px" fixed-header calculate-widths hide-default-footer v-if="!isMobile"
                        no-data-text="No hay una programación registrada" :headers="headersSecuencia"
                        :items="editedSecuencia" sort-by="orden">

                        <template v-slot:[`item.grupo`]="{ item }">
                          <v-chip v-if="grupos.find((gr) => gr.id === item.id_configuracion)">
                            {{ grupos.find((gr) => gr.id === item.id_configuracion).nombre }}
                          </v-chip>
                        </template>

                        <template v-slot:[`item.minutos`]="{ item }">
                          <v-text-field v-if="item === edit_duracion" v-model="item.minutos" type="number" :max="300"
                            @input="onMinutosChangedEdit(item)" />
                          <span v-else>{{ formatDuracion(item.minutos) }}</span>
                        </template>

                        <template v-slot:[`item.hora_inicio`]="{ item }">
                          {{ item.hora_inicio }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon small color="grey" v-if="item === edit_duracion" @click="saveSecuenciaDuracion()">
                            mdi-check </v-icon>
                          <v-icon small color="grey" v-else @click="editSecuenciaDuracion(item)"> mdi-pencil-outline
                          </v-icon>
                          <v-icon small color="grey" @click="moveUp(item)"> mdi-chevron-up </v-icon>
                          <v-icon small color="grey" @click="moveDown(item)"> mdi-chevron-down </v-icon>
                          <v-icon small color="grey" @click="deleteSecuencia(item)"> mdi-close </v-icon>
                        </template>
                      </v-data-table>
                      <draggable v-else v-model="editedSecuencia" :options="{ handle: '.drag-handle' }"
                        @end="onDragEndDuracion" tag="div">
                        <div v-for="(item, index) in editedSecuencia" :key="index">
                          <v-list-item class="d-flex align-center">
                            <v-list-item-content>
                              <v-list-item-title class="d-flex align-center">
                                <v-icon left class="mdi-drag drag-handle">mdi-drag</v-icon>
                                {{ grupos.find(gr => gr.id.toString() === item.id_configuracion.toString()).nombre }}
                              </v-list-item-title>
                              <v-list-item-subtitle>{{ formatDuracion(item.minutos) }} - {{ item.hora_inicio
                                }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon small color="grey" @click.stop="deleteSecuencia(item)">mdi-close</v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </div>
                      </draggable>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="auto" class="mr-auto">
                      <v-btn small fab color="primary" @click="goToStep(1)" class="mb-n6">
                        <v-icon>
                          mdi-chevron-left
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn small color="primary" fab @click="stepper_pag = 3" class="mb-n6"
                        :disabled="editedSecuencia.length === 0 || is_buttton_disabled">
                        <v-icon>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-stepper-content>

            <v-stepper-step :complete="stepper_pag > 3" step="3" v-if="$vuetify.breakpoint.smAndDown">
              Configuración de repetición
            </v-stepper-step>
            <v-stepper-content step="3">
              <v-card-text class="mt-n6">
                <v-container>
                  <!-- Detalles para configuración de repeticion -->
                  <v-alert text dense color="teal" border="left" icon="mdi-information-variant-circle">
                    <span> ¿Quieres repetir la configuración de programación?</span>
                  </v-alert>
                  <v-row class="d-flex justify-center">
                    <v-radio-group v-model="itemSecuencial.is_repetir" @change="handleRepetirCambio" row>
                      <v-radio label="Si" value="si" class="pl-4 mb-1" color="teal darken-2"></v-radio>
                      <v-radio label="No" value="no" class="pl-4 mb-1" color="teal darken-2"></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" sm="12" md="6" v-if="itemSecuencial.is_repetir === 'si'" class="mt-3">
                      <v-text-field v-model="itemSecuencial.repetir_cada" label="Repetir cada" rounded outlined
                        :suffix="itemSecuencial.repetir_cada == 1 ? 'día' : 'días'" prepend-icon="mdi-calendar-refresh"
                        hide-details="">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="auto" class="mr-auto">
                      <v-btn color="primary" small fab @click="goToStep(2)" class="mb-n6">
                        <v-icon>
                          mdi-chevron-left
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn small color="primary" fab @click="stepper_pag = 4" class="mb-n6"
                        v-if="(itemSecuencial.is_repetir === 'no' || (itemSecuencial.is_repetir === 'si' && itemSecuencial.repetir_cada))">
                        <v-icon>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                      <v-btn small color="primary" fab disabled v-else>
                        <v-icon>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-stepper-content>

            <v-stepper-step step="4" v-if="$vuetify.breakpoint.smAndDown">
              Secuencia de apagado
            </v-stepper-step>
            <v-stepper-content step="4">
              <v-card-text class="mt-n6">
                <v-container>
                  <!-- Detalles para secuencia finalizada -->
                  <v-alert v-model="alert_ultimo_param" type="error" dismissible @input="handleAlertDismiss">
                    <strong>Advertencia:</strong> No puedes eliminar el último elemento.
                  </v-alert>
                  <v-row>
                    <v-col cols="12" class="rounded overflow-y-auto mt-3" :class="!isMobile ? 'elevation-2' : ''">
                      <v-data-table height="180px" fixed-header calculate-widths hide-default-footer v-if="!isMobile"
                        no-data-text="No hay parámetro registrado" :headers="headerParametros"
                        :items="editedVariadoresInverse">
                        <template v-slot:[`item.nombre_parametro`]="{ item }">
                          <v-chip>
                            {{ item.nombre_parametro }}
                          </v-chip>
                        </template>

                        <template v-slot:[`item.retardo`]="{ item }">
                          <v-text-field v-if="item.id === editingItemId" v-model="text_value_retardo" type="number"
                            :max="300" @input="onRetardoChangeInverse" />
                          <span v-else>{{ item.retardo_apagado }} seg</span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon small color="grey" v-if="item.id !== editingItemId" @click="editParamInverse(item)">
                            mdi-pencil-outline </v-icon>
                          <v-icon small color="grey" v-else @click="saveParamInverse()"> mdi-check </v-icon>
                          <v-icon small color="grey" @click="moveUpParamInverse(item)"> mdi-chevron-up </v-icon>
                          <v-icon small color="grey" @click="moveDownParamInverse(item)"> mdi-chevron-down </v-icon>
                          <v-icon small color="grey" @click="deleteParametroInverse(item)"> mdi-close </v-icon>
                        </template>
                      </v-data-table>
                      <draggable v-else v-model="editedVariadoresInverse" :options="{ handle: '.drag-handle' }"
                        @end="onDragEndInverse" tag="div">
                        <div v-for="(item, index) in editedVariadoresInverse" :key="item.id + '-' + index">
                          <v-list-item class="d-flex align-center">
                            <v-list-item-content>
                              <v-list-item-title class="d-flex align-center">
                                <v-icon left class="mdi-drag drag-handle">mdi-drag</v-icon>
                                {{ item.nombre_parametro }}
                              </v-list-item-title>
                              <v-list-item-subtitle>{{ item.retardo_apagado }} seg</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon small color="grey" @click.stop="deleteParametroInverse(item)">mdi-close</v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </div>
                      </draggable>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="auto" class="mr-auto">
                      <v-btn color="primary" small fab @click="goToStep(3)" class="mb-n6">
                        <v-icon>
                          mdi-chevron-left
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn color="primary" @click="valvulasModoManual" class="mb-n6"
                        :disabled="last_item_deleted || is_buttton_disabled || itemSecuencial.nombre.trim() === '' || nombreExists">
                        Guardar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <!-- Dialog delete -->
    <v-dialog v-model="dialog_delete" max-width="580px">
      <v-card>
        <v-card-title class="headline subtitle-1">Eliminar programación</v-card-title>
        <v-card-text>
          <p>¿Está seguro que desea eliminar la programación <strong>{{ nombre_secuencia }}</strong>?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal darken-3" text @click="close('dialog_delete')"> Cancelar </v-btn>
          <v-btn color="red darken-1" text @click="deleteProgramaciones(selected_id_secuencia)">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog para ver si hay valvulas en modo manual -->
    <v-dialog v-model="dialog_modo_manual" width="540px">
      <v-card class="pa-2">

        <!-- Mostrar la advertencia inicial -->
        <template v-if="status === 'initial'">
          <v-card-title class="headline">
            <v-icon color="red darken-1" class="mr-2">mdi-alert-circle</v-icon>
            <strong>¡Atención!</strong>
          </v-card-title>
          <v-card-text class="subtitle-2">
            Las siguientes electroválvulas del grupo seleccionado están en modo <strong>Manual</strong>.
            Para poder guardar la programación, debes cambiar el modo de operación de estas electroválvulas a
            <strong>Automático</strong>.
          </v-card-text>
          <v-card-text>
            <v-chip v-for="(ev, key) in valvulasIsManual" :key="key" class="mx-2" color="warning">
              {{ electrovalvulas.find((el) => el.id === ev).nombre }}
            </v-chip>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="red darken-1" @click="closeDialogValvesToManual()" text>Cancelar</v-btn>
            <v-btn color="primary" @click="updateValvesToAuto()" :loading="loadingButton">Cambiar a automático</v-btn>
          </v-card-actions>
        </template>

        <!-- Mostrar si la operación fue exitosa -->
        <template v-if="status === 'success'">
          <v-card-title class="headline">
            <v-icon color="success" class="mr-2">mdi-check-circle</v-icon>
            <strong>Completado</strong>
          </v-card-title>
          <v-card-text class="subtitle-2">
            Se han cambiado las electroválvulas a modo <strong>Automático</strong>.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="closeDialogValvesToManual()" text>Aceptar</v-btn>
          </v-card-actions>
        </template>

        <!-- Mostrar si hubo error en la operación -->
        <template v-if="status === 'error'">
          <v-card-title class="headline">
            <v-icon color="red darken-2" class="mr-2">mdi-close-circle</v-icon>
            <strong>Vuelve a intentarlo</strong>
          </v-card-title>
          <v-card-text class="subtitle-2">
            No se ha podido cambiar el modo de operación de las electroválvulas a <strong>Automático</strong>.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="red darken-1" @click="closeDialogValvesToManual()" text>Cancelar</v-btn>
            <v-btn color="teal darken-2" dark @click="updateValvesToAuto()" :loading="loadingButton">Reintentar</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";
export default {
  components: {
    draggable,
  },
  props: ["grupos", "electrovalvulas"],
  data() {
    return {
      editingItem: null,
      text_value_retardo: null,
      editingItemId: null,
      edit_retardo: null,
      edit_retardo_inverse: null,
      edit_duracion: null,
      alert_ultimo_param: false,
      last_item_deleted: false,
      selected_id_secuencia: null,
      nombre_secuencia: "",
      id_parametro: null,
      id_variador: null,
      nombre_parametro: "",
      showErrorForName: false,
      loadingButton: false,
      status: "initial",
      message_name_exist: "Este nombre de programación ya está en uso.",
      stepper_pag: 1,
      dialog_secuencial: false,
      dialog_delete: false,
      dialog_modo_manual: false,
      valvulasIsManual: [],
      variadores: null,
      parametros: [],
      seleccionArranque: null,
      is_buttton_disabled: false,
      programaciones: [],
      units: ['seg', 'min'],
      unitsDuracion: ['min', 'h'],
      programacionesSecuenciales: [],
      headersSecuenciales: [
        { text: "Habilitación", value: "is_enabled", width: "10%", class: "primary--text" },
        { text: "Estado", value: "is_activa", width: "7%", class: "primary--text" },
        { text: "Nombre", value: "titulo", width: "10%", class: "primary--text" },
        { text: "Fecha", value: "fecha", width: "10%", class: "primary--text" },
        { text: "Hora inicio", value: "hora_inicio", width: "10%", class: "primary--text" },
        { text: "Grupos", value: "grupo", width: "20%", class: "primary--text" },
        { text: "Repetir cada", value: "repetir_cada", width: "10%", class: "primary--text" },
        { text: "Acciones", value: "actions", sortable: false, width: "5%", class: "primary--text" },
      ],
      headersSecuencia: [
        { text: "Orden", value: "orden", width: "7%", sortable: false, class: "primary--text" },
        { text: "Grupo", value: "grupo", width: "40%", sortable: false, class: "primary--text" },
        { text: "Hora inicio", value: "hora_inicio", width: "22%", sortable: false, class: "primary--text" },
        { text: "Duración", value: "minutos", width: "13%", sortable: false, class: "primary--text" },
        { text: "Acciones", value: "actions", width: "18%", sortable: false, class: "primary--text" },
      ],
      headerParametros: [
        { text: "Orden", value: "orden", width: "10%", sortable: false, class: "primary--text" },
        { text: "Nombre", value: "nombre_parametro", width: "58%", sortable: false, class: "primary--text" },
        { text: "Retardo", value: "retardo", width: "15%", sortable: false, class: "primary--text" },
        { text: "Acciones", value: "actions", width: "17%", sortable: false, class: "primary--text" },
      ],
      itemSecuencial: {
        id: 0,
        nombre: "",
        repetir_cada: 0,
        is_repetir: false,
        fecha: new Date().toISOString().substr(0, 10),
        hora_inicio: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        secuencia: [],
      },
      itemParametro: {
        id: 0,
        id_variador: 0,
        descripcion: "",
      },
      itemParametroInverse: {
        id: 0,
        id_variador: 0,
        descripcion: "",
      },
      disabledItems: [],
      editedVariadores: [],
      editedVariadoresInverse: [],
      editedSecuencia: [],
      deletedSecuencia: [],
      deletedParametros: [],
      addSecuencia: [],
      orden: 1,
      gruposSelected: [],
      param_selected: [],
      minutos_selected: {
        value: 0,
        unit: 'min',
      },
      retardo_selected: {
        value: 0,
        unit: 'seg',
      },
      primera_fecha_introducida: "",
      primera_hora_introducida: "",
      is_param_added: false,
      retardo: null,
      retardo_apagado: null,
      id_param: null,
      id_param_apagado: null,
      intervalGetCoreData: null,
    };
  },
  methods: {
    closeDialogValvesToManual() {
      this.status = "initial";
      this.dialog_modo_manual = false;
    },
    handleSeleccionArranqueChange() {
      if (this.seleccionArranque === 'no') {
        this.editedVariadores = [];
        this.editedVariadoresInverse = [];
        this.parametros.forEach((param, index) => {
          this.$set(this.parametros, index, { ...param, disabled: false });
        });
      }
    },
    onDragEndDuracion() {
      this.updateOrden();
      this.recalcularFechaYHorasMovil();
    },

    recalcularFechaYHorasMovil() {
      if (this.editedSecuencia.length > 0) {
        let baseFecha = this.primera_fecha_introducida;
        let baseHora = this.primera_hora_introducida;

        // Aseguramos que el primer elemento tenga la fecha y hora de inicio base
        this.$set(this.editedSecuencia, 0, {
          ...this.editedSecuencia[0],
          fecha: baseFecha,
          hora_inicio: baseHora
        });

        // Recalcula las fechas y horas para cada elemento de la secuencia
        this.editedSecuencia.forEach((element, index) => {
          if (index > 0) {
            // Acumular minutos de los elementos anteriores
            let acumMinutos = this.editedSecuencia
              .slice(0, index)
              .reduce((acc, curr) => acc + curr.minutos, 0);

            let { fechaInicio, horaInicio } = this.calcularNuevaFechaHoraInicio(baseFecha, baseHora, acumMinutos);

            this.$set(this.editedSecuencia, index, {
              ...element,
              fecha: fechaInicio,
              hora_inicio: horaInicio
            });
          }
        });
      }
    },

    formatDuracion(duracion) {
      // No necesita verificar la unidad ya que siempre está en minutos
      return `${duracion} min`;
    },

    formatRetardo(retardo) {
      // No necesita verificar la unidad ya que siempre está en segundos
      return `${retardo} seg`;
    },

    onDragEnd() {
      this.editedVariadores.forEach((item, index) => {
        item.orden = index + 1;
      });
    },

    onDragEndInverse() {
      this.editedVariadoresInverse.forEach((item, index) => {
        item.orden = index + 1;
      });
    },

    valvulasModoManual() {
      this.valvulasIsManual = [];
      // Filtra las electroválvulas que están en modo manual
      this.editedSecuencia.forEach(item => {
        const grupo = this.grupos.find(grupo => grupo.id === item.id_configuracion);
        grupo.rels.forEach(ev => {
          // Saco id de los rels y los comparo con los id de las electroválvulas sacando su is_auto
          const electrovalvula = this.electrovalvulas.find(elect => elect.id === ev.id_electrovalvula);
          if (electrovalvula.is_auto === 0) {
            this.valvulasIsManual.push(electrovalvula.id);
          }
        });
      });

      if (this.valvulasIsManual.length > 0) {
        this.dialog_modo_manual = true;
      } else {
        this.insertProgramaciones();
      }

    },

    updateValvesToAuto() {
      this.loadingButton = true;
      // Obtengo todas las electroválvulas que están en modo manual
      const ids_valvulas = this.valvulasIsManual;

      axios.put(`${process.env.VUE_APP_API_URL}/updateValvesToAuto`, { ids_valvulas })
        .then(response => {
          console.log(response.data);
          setTimeout(() => {
            this.loadingButton = false;
            this.status = "success";
          }, 1000);
        })
        .catch(error => {
          setTimeout(() => {
            this.loadingButton = false;
            this.status = "error";
          }, 1000);
          console.log(error);
        })
        .finally(() => {
          this.$emit("updateElectrovalvulas");
      });

    },

    onRetardoChangeInverse() {
      let parsedValue = parseInt(this.text_value_retardo);
      if (isNaN(parsedValue)) {
        this.text_value_retardo = '';
      } else if (parsedValue < 0) {
        this.text_value_retardo = 0;
      } else if (parsedValue > 300) {
        this.text_value_retardo = 0;
      } else if (parsedValue.toString().length > 3) {
        this.text_value_retardo = '';
      } else {
        this.text_value_retardo = parsedValue;
      }
    },

    onRetardoChange() {
      const parsedValue = parseInt(this.retardo_selected.value);
      if (isNaN(parsedValue) || parsedValue < 0) {
        this.retardo_selected.value = '';
      } else if (this.retardo_selected.unit === 'min' && parsedValue > 5) {
        this.retardo_selected.value = 5;
      } else if (this.retardo_selected.unit === 'seg' && parsedValue > 300) {
        this.retardo_selected.value = 300;
      } else if (this.retardo_selected.unit === 'min' && parsedValue.toString().length > 1) {
        this.retardo_selected.value = parsedValue.toString().slice(0, 1);
      } else if (this.retardo_selected.unit === 'seg' && parsedValue.toString().length > 3) {
        this.retardo_selected.value = parsedValue.toString().slice(0, 3);
      } else {
        this.retardo_selected.value = parsedValue;
      }
    },

    onRetardoChangeEdit(item) {
      const parsedValue = parseInt(item.retardo);
      if (isNaN(parsedValue)) {
        item.retardo = '';
      } else if (parsedValue < 0) {
        item.retardo = 0;
      } else if (parsedValue > 300) {
        item.retardo = 0;
      } else if (parsedValue.toString().length > 3) {
        item.retardo = '';
      } else {
        item.retardo = parsedValue;
      }
      item.unit = this.retardo_selected.unit;
    },

    onMinutosChanged() {
      const parsedValue = parseInt(this.minutos_selected.value);
      if (isNaN(parsedValue) || parsedValue < 0) {
        this.minutos_selected.value = '';
      } else if (this.minutos_selected.unit === 'h' && parsedValue > 23) {
        this.minutos_selected.value = 23;
      } else if (this.minutos_selected.unit === 'min' && parsedValue > 1439) {
        this.minutos_selected.value = 1439;
      } else {
        this.minutos_selected.value = parsedValue;
      }
    },

    onMinutosChangedEdit(item) {
      const parsedValue = parseInt(item.minutos);

      if (isNaN(parsedValue)) {
        item.minutos = '';
      } else if (parsedValue < 0) {
        item.minutos = 0;
      } else if (parsedValue > 1439) {
        item.minutos = 0;
      } else if (parsedValue.toString().length > 4) {
        item.minutos = '';
      } else {
        item.minutos = parsedValue;
      }
    },

    handleAlertDismiss() {
      this.last_item_deleted = false;
    },

    resetParams() {
      this.parametros = this.parametros.map(param => ({
        ...param,
        disabled: false
      }));
    },

    onGrupoChange() {
      this.$refs.gruposSelect.blur();
    },

    resetGroups() {
      this.$emit('reset-groups');
    },

    editParam(item) {
      this.edit_retardo = item;
      this.is_buttton_disabled = true;
    },

    saveParam() {
      this.edit_retardo = null;
      this.is_buttton_disabled = false;
    },

    editParamInverse(item) {
      this.edit_retardo_inverse = JSON.parse(JSON.stringify(item));
      this.text_value_retardo = this.edit_retardo_inverse.retardo_apagado;
      this.editingItemId = item.id;
      this.is_buttton_disabled = true;
    },

    saveParamInverse() {
      const originalItem = this.editedVariadoresInverse.find(item => item.id === this.edit_retardo_inverse.id);
      if (originalItem) {
        // Actualiza el valor de retardo del item original
        originalItem.retardo_apagado = this.text_value_retardo;
      }
      this.edit_retardo_inverse = null;
      this.text_value_retardo = null;
      this.editingItemId = null;
      this.is_buttton_disabled = false;
    },

    editSecuenciaDuracion(item) {
      this.edit_duracion = item;
      this.is_buttton_disabled = true;

    },

    saveSecuenciaDuracion() {
      this.edit_duracion = null;
      this.is_buttton_disabled = false;
      this.recalcularFechasYHoras()
    },

    deleteItemSecuencial(item) {
      this.selected_id_secuencia = item.id;
      this.nombre_secuencia = item.titulo;
      this.dialog_delete = true;
    },

    handleRepetirCambio(value) {
      if (value === 'no') {
        this.itemSecuencial.repetir_cada = 0;
      }
    },

    formatDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },

    goToStep(step) {
      // Si el usuario quiere ir al paso 2, debe tener en cuenta que el nombre de la programación es requerido
      if (step === 2 && !this.itemSecuencial.nombre.trim()) {
        // Activar el indicador de error para el nombre si está vacío
        this.showErrorForName = true;
        return; // Detener la ejecución para no avanzar al paso 2
      } else {
        // Desactivar el indicador de error para el nombre si el paso no es 2 o el nombre no está vacío
        this.showErrorForName = false;
      }
      this.stepper_pag = step;

    },

    updateSelectedParameters(newSelection) {
      // Actualiza la lista de parámetros seleccionados basada en la selección del usuario
      this.param_selected = newSelection;
      this.$refs.miSelect.blur();
    },

    cleanObject() {
      this.itemSecuencial = {
        id: 0,
        nombre: "",
        repetir_cada: 0,
        is_repetir: false,
        fecha: new Date().toISOString().substr(0, 10),
        hora_inicio: new Date().toISOString().substr(11, 5),
        secuencia: [],
      };
      this.addSecuencia = [];
      this.deletedSecuencia = [];
      this.editedSecuencia = [];
      this.orden = 1;
      this.gruposSelected = [];
      this.param_selected = [];
      this.minutos_selected = [];
      this.editedVariadores = [];
      this.editedVariadoresInverse = [];
    },

    addParam() {
      this.param_selected.forEach((param) => {
        // Convertir el retardo a segundos si está en minutos
        const retardoEnSegundos = this.retardo_selected.unit === 'min'
          ? this.retardo_selected.value * 60
          : this.retardo_selected.value;

        const newItem = {
          id: param.id,
          id_variador: param.id_variador,
          nombre_parametro: param.descripcion,
          retardo: retardoEnSegundos,
          unit: 'seg', // Siempre almacenar la unidad en segundos
          retardo_apagado: retardoEnSegundos,
          orden: this.editedVariadores.length + 1 && this.editedVariadoresInverse.length + 1,
        };

        this.editedVariadores.push(newItem);
        this.editedVariadoresInverse.unshift(newItem);

        // Deshabilitar el parámetro seleccionado
        const index = this.parametros.findIndex(p => p.id === param.id);
        if (index !== -1) {
          this.parametros[index].disabled = true;
        }

      });

      this.is_param_added = true;
      this.retardo_selected = { value: 0, unit: 'seg' };
      this.param_selected = [];
    },

    add() {
      if (this.editedSecuencia.length === 0) {
        this.primera_fecha_introducida = this.itemSecuencial.fecha;
        this.primera_hora_introducida = this.itemSecuencial.hora_inicio;
      }

      // Convertir la duración a minutos si está en horas
      let duracionEnMinutos = this.minutos_selected.unit === 'h'
        ? this.minutos_selected.value * 60
        : this.minutos_selected.value;

      if (this.editedSecuencia.length > 0) {
        const ultimoElemento = this.editedSecuencia[this.editedSecuencia.length - 1];
        if (ultimoElemento.fecha && ultimoElemento.hora_inicio && !isNaN(ultimoElemento.minutos)) {
          const { fechaInicio, horaInicio } = this.calcularNuevaFechaHoraInicio(
            ultimoElemento.fecha,
            ultimoElemento.hora_inicio,
            parseInt(ultimoElemento.minutos)
          );

          this.itemSecuencial.fecha = fechaInicio;
          this.itemSecuencial.hora_inicio = horaInicio;
        }
      }

      const newItem = {
        id_configuracion: this.gruposSelected[0].id,
        minutos: duracionEnMinutos,
        unit: 'min', // Siempre almacenar la unidad en minutos
        fecha: this.itemSecuencial.fecha,
        hora_inicio: this.itemSecuencial.hora_inicio,
        orden: this.editedSecuencia.length + 1
      };

      this.editedSecuencia.push(newItem);
      this.addSecuencia.push(newItem);
      this.updateOrden();

      const index = this.grupos.findIndex(g => g.id === this.gruposSelected[0].id);
      if (index !== -1) {
        this.$set(this.grupos, index, { ...this.grupos[index], disabled: true });
      }
      this.minutos_selected = { value: 0, unit: 'min' };
      this.itemSecuencial.fecha = this.primera_fecha_introducida;
      this.itemSecuencial.hora_inicio = this.primera_hora_introducida;
      this.gruposSelected = [];
    },

    calcularNuevaFechaHoraInicio(fecha, hora, minutos) {
      // Calcula la nueva fecha y hora de inicio basándose en la fecha y hora de inicio del último elemento
      let fechaHora = new Date(`${fecha}T${hora}`);
      fechaHora = new Date(fechaHora.getTime() + minutos * 60000);
      const fechaFormateada = this.formatDate(fechaHora);
      const horaFormateada = fechaHora.toTimeString().substring(0, 5);

      return { fechaInicio: fechaFormateada, horaInicio: horaFormateada };
    },

    updateOrden() {
      // Actualiza el orden de los elementos
      this.editedSecuencia.forEach((item, index) => {
        item.orden = index + 1;
      });
    },

    updateOrdenParametros() {
      // Actualiza el orden de los elementos
      this.editedVariadores.forEach((item, index) => {
        item.orden = index + 1;
      });
    },

    deleteSecuencia(itemToDelete) {
      // Si el elemento a eliminar ya existe en la base de datos, se agrega al array de eliminados
      const index = this.editedSecuencia.indexOf(itemToDelete);
      if (index) {
        this.deletedSecuencia.push(itemToDelete);
      }
      // Habilito el grupo seleccionado
      const indexGrupo = this.grupos.findIndex(g => g.id === itemToDelete.id_configuracion);
      if (indexGrupo !== -1) {
        this.$set(this.grupos, indexGrupo, { ...this.grupos[indexGrupo], disabled: false });
      }

      this.editedSecuencia.splice(index, 1);
      this.updateOrden();
      this.recalcularFechaYHorasMovil();
    },

    deleteParametro(itemToDelete) {
      const index = this.editedVariadores.indexOf(itemToDelete);
      if (index) {
        this.deletedParametros.push(itemToDelete);
      }
      // Habilito el parámetro seleccionado
      const indexParam = this.parametros.findIndex(p => p.id === itemToDelete.id);
      if (indexParam !== -1) {
        this.$set(this.parametros, indexParam, { ...this.parametros[indexParam], disabled: false });
      }

      this.editedVariadores.splice(index, 1);
      this.editedVariadoresInverse.splice(index, 1);

      this.updateOrden();
    },

    deleteParametroInverse(itemToDelete) {
      if (this.editedVariadoresInverse.length === 1) {
        this.alert_ultimo_param = true;
        this.last_item_deleted = true;
      } else {
        const index = this.editedVariadoresInverse.indexOf(itemToDelete);
        this.editedVariadoresInverse.splice(index, 1);
      }
    },

    getParametros() {
      axios
        .post(process.env.VUE_APP_API_URL + "/getParametros", {
          id_dispositivo: localStorage.getItem("dispositivos"),
          id_finca: localStorage.getItem("id_finca"),
        })
        .then((res) => {
          this.variadores = res.data;

          this.variadores.forEach((variador) => {
            if (variador.parametros) {
              variador.parametros.forEach((parametro) => {
                this.parametros.push(parametro);
              });
            }

          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          // this.done = true;
        });
    },

    handleSwitchChange(item) {
      let valvulasIsManual = [];

      item.grupo.forEach((secuencia) => {
        const grupo = this.grupos.find((grupo) => grupo.id === secuencia.id_configuracion);
        grupo.rels.forEach((ev) => {
          const electrovalvula = this.electrovalvulas.find((elect) => elect.id === ev.id_electrovalvula);
          if (electrovalvula.is_auto === 0 && !valvulasIsManual.includes(electrovalvula.id)) {
            valvulasIsManual.push(electrovalvula.id);
          }
        });
      });

      if (valvulasIsManual.length > 0) {
        // Hay válvulas en modo manual, abrir diálogo
        this.valvulasIsManual = valvulasIsManual;
        this.dialog_modo_manual = true;
        item.is_enabled = 1;
        this.$nextTick(() => {
          item.is_enabled = 0;
        });
      } else {
        this.updateIsEnabledProgramacion(item);
      }
    },

    updateIsEnabledProgramacion(item) {
      console.log(item)
      axios.post(process.env.VUE_APP_API_URL + "/mqtt/updateIsEnabledProgramacion", {
        id_dispositivo: localStorage.getItem("dispositivos"),
        id_secuencia: item.id,
        is_enabled: item.is_enabled,
        function: "updateIsEnabledProgramacion"
      })
        .finally(() => {
          this.getProgramacionesSecuenciales();
        });

    },

    moveUp(item) {
      const index = this.editedSecuencia.findIndex(x => x === item);
      if (index > 0) {
        // Intercambio nombres de grupos y minutos de duración
        const grupoActual = this.editedSecuencia[index].id_configuracion;
        this.editedSecuencia[index].id_configuracion = this.editedSecuencia[index - 1].id_configuracion;
        this.editedSecuencia[index - 1].id_configuracion = grupoActual;

        const minutosActual = this.editedSecuencia[index].minutos;
        this.editedSecuencia[index].minutos = this.editedSecuencia[index - 1].minutos;
        this.editedSecuencia[index - 1].minutos = minutosActual;

        // Extraemos los elementos que queremos intercambiar para no mutar directamente el estado
        let itemToMoveUp = { ...this.editedSecuencia[index - 1] };

        // Intercambiamos los valores
        itemToMoveUp.id_configuracion = this.editedSecuencia[index].id_configuracion;

        itemToMoveUp.minutos = this.editedSecuencia[index].minutos;

        // Actualizamos el estado de forma reactiva
        this.$set(this.editedSecuencia, index, itemToMoveUp);

        // Recalculamos las fechas y horas de inicio
        this.recalcularFechasYHoras();

        this.updateOrden();
      }
    },

    recalcularFechasYHoras() {
      if (this.editedSecuencia.length > 0) {
        let baseFecha = this.editedSecuencia[0].fecha;
        let baseHora = this.editedSecuencia[0].hora_inicio;

        this.$set(this.editedSecuencia, 0, {
          ...this.editedSecuencia[0],
          fecha: baseFecha,
          hora_inicio: baseHora
        });

        this.editedSecuencia.forEach((element, index) => {
          if (index > 0) {
            let acumMinutos = this.editedSecuencia.slice(0, index)
              .reduce((acc, curr) => {
                let minutos = curr.minutos;
                if (curr.unit === 'h') {
                  minutos *= 60;
                }
                return acc + minutos;
              }, 0);

            let { fechaInicio, horaInicio } = this.calcularNuevaFechaHoraInicio(baseFecha, baseHora, acumMinutos);

            this.$set(this.editedSecuencia, index, {
              ...element,
              fecha: fechaInicio,
              hora_inicio: horaInicio
            });
          }
        });
      }
    },

    moveDown(item) {
      const index = this.editedSecuencia.findIndex(x => x === item);
      if (index > 0) {
        // Intercambio nombres de grupos y minutos de duración
        const grupoActual = this.editedSecuencia[index].id_configuracion;
        this.editedSecuencia[index].id_configuracion = this.editedSecuencia[index + 1].id_configuracion;
        this.editedSecuencia[index + 1].id_configuracion = grupoActual;

        const minutosActual = this.editedSecuencia[index].minutos;
        this.editedSecuencia[index].minutos = this.editedSecuencia[index + 1].minutos;
        this.editedSecuencia[index + 1].minutos = minutosActual;

        // Extraemos los elementos que queremos intercambiar para no mutar directamente el estado
        let itemToMoveUp = { ...this.editedSecuencia[index + 1] };

        // Intercambiamos los valores
        itemToMoveUp.id_configuracion = this.editedSecuencia[index].id_configuracion;

        itemToMoveUp.minutos = this.editedSecuencia[index].minutos;

        // Actualizamos el estado de forma reactiva
        this.$set(this.editedSecuencia, index, itemToMoveUp);

        // Recalculamos las fechas y horas de inicio
        this.recalcularFechasYHoras();

        this.updateOrden();
      }
    },

    moveUpParam(item) {
      const index = this.editedVariadores.findIndex(x => x === item);
      if (index > 0) {
        // Extrae el elemento que deseas mover hacia arriba
        const itemToMoveUp = this.editedVariadores.splice(index, 1)[0];
        // Inserta el elemento en la posición anterior
        this.editedVariadores.splice(index - 1, 0, itemToMoveUp);

        // Actualiza el orden de los elementos
        this.updateOrdenParametros();
      }
    },

    moveDownParam(item) {
      const index = this.editedVariadores.findIndex(x => x === item);
      if (index < this.editedVariadores.length - 1) {
        // Extrae el elemento que deseas mover hacia abajo
        const itemToMoveDown = this.editedVariadores.splice(index, 1)[0];
        // Inserta el elemento en la posición siguiente
        this.editedVariadores.splice(index + 1, 0, itemToMoveDown);

        // Actualiza el orden de los elementos
        this.updateOrdenParametros();
      }
    },

    moveUpParamInverse(item) {
      const index = this.editedVariadoresInverse.findIndex(x => x === item);
      if (index > 0) {
        // Intercambio el nombre del parámetro
        const nombreParametroActual = this.editedVariadoresInverse[index].nombre_parametro;
        this.editedVariadoresInverse[index].nombre_parametro = this.editedVariadoresInverse[index - 1].nombre_parametro;
        this.editedVariadoresInverse[index - 1].nombre_parametro = nombreParametroActual;

        // Intercambio el retardo
        const retardoActual = this.editedVariadoresInverse[index].retardo_apagado;
        this.editedVariadoresInverse[index].retardo_apagado = this.editedVariadoresInverse[index - 1].retardo_apagado;
        this.editedVariadoresInverse[index - 1].retardo_apagado = retardoActual;
      }
    },

    moveDownParamInverse(item) {
      const index = this.editedVariadoresInverse.findIndex(x => x === item);
      if (index < this.editedVariadoresInverse.length - 1) {
        // Intercambio el nombre del parámetro
        const nombreParametroActual = this.editedVariadoresInverse[index].nombre_parametro;
        this.editedVariadoresInverse[index].nombre_parametro = this.editedVariadoresInverse[index + 1].nombre_parametro;
        this.editedVariadoresInverse[index + 1].nombre_parametro = nombreParametroActual;

        // Intercambio el retardo
        const retardoActual = this.editedVariadoresInverse[index].retardo_apagado;
        this.editedVariadoresInverse[index].retardo_apagado = this.editedVariadoresInverse[index + 1].retardo;
        this.editedVariadoresInverse[index + 1].retardo_apagado = retardoActual;
      }
    },

    close(dialog) {
      if (dialog === "dialog_delete") this.dialog_delete = false;
      if (dialog === "dialog_secuencial") this.dialog_secuencial = false;
      this.stepper_pag = 1;
      this.seleccionArranque = null;
      this.is_param_added = false;
      this.is_buttton_disabled = false;
      this.alert_ultimo_param = false;
      this.cleanObject();
      this.resetParams();
      this.resetGroups();
    },

    getProgramaciones() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/getProgramaciones`, {
          id_dispositivo: localStorage.getItem("dispositivos"),
          id_finca: JSON.parse(localStorage.getItem("id_finca")),
        })
        .then((res) => {
          this.programaciones = res.data;
        })
        .catch((err) => console.error(err))
        .finally(() => {
          // this.done = true;
        });
    },

    getProgramacionesSecuenciales() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/getProgramacionesSecuenciales`, {
          id_dispositivo: localStorage.getItem("dispositivos"),
          id_finca: JSON.parse(localStorage.getItem("id_finca")),
        })
        .then((res) => {
          this.programacionesSecuenciales = res.data;
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.getCoreData();
        });
    },

    deleteProgramaciones(idSecuencia) {
      axios
        .put(`${process.env.VUE_APP_API_URL}/deleteProgramaciones`, {
          id_secuencia: idSecuencia,
        })
        .finally(() => {
          this.close("dialog_delete");
          this.getProgramacionesSecuenciales();
        });
    },

    insertProgramaciones() {
      if (this.editingItem) {
        // Actualizo programaciones
        this.deleteProgramaciones(this.editingItem.id);
      }
      this.editingItem = null;
      const parametros = this.editedVariadores.map(variador => ({
        id_parametro: variador.id,
        retardo: variador.retardo
      }));

      const parametros_finalizados = this.editedVariadoresInverse.map(variador => ({
        id_parametro: variador.id,
        retardo: variador.retardo_apagado
      }));

      const secuencias = this.editedSecuencia.map(secuencia => ({
        titulo: this.itemSecuencial.nombre,
        id_configuracion: secuencia.id_configuracion,
        minutos: secuencia.minutos,
        fecha: secuencia.fecha,
        hora_inicio: secuencia.hora_inicio,
        repetir_cada: this.itemSecuencial.repetir_cada,
      }));

      axios
        .post(`${process.env.VUE_APP_API_URL}/insertProgramaciones`, {
          id_dispositivo: localStorage.getItem("dispositivos"),
          id_finca: JSON.parse(localStorage.getItem("id_finca")),
          parametros: parametros,
          parametros_finalizados: parametros_finalizados,
          secuencias: secuencias,
        })
        .then((res) => {
          if (res && res.data) {
            this.close("dialog_secuencial");
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.getProgramacionesSecuenciales();
        });
    },

    openNewProgramacionDialog() {
      this.editingItem = false
      this.dialog_secuencial = true;
    },

    editItemSecuencial(item) {
      if (item.secuencia_variadores_arranque === "[]") {
        this.id_param = null
        this.retardo = null
        this.seleccionArranque = 'no'
      } else {
        const arranque = JSON.parse(item.secuencia_variadores_arranque);

        // Deshabilito los parámetros seleccionados
        arranque.forEach((parametro) => {
          const index = this.parametros.findIndex(p => p.id === parametro.id);
          if (index !== -1) {
            this.$set(this.parametros, index, { ...this.parametros[index], disabled: true });
          }
        });

        this.seleccionArranque = 'si'
      }

      if (item.secuencia_variadores_apagado === "[]") {
        this.id_param_apagado = null
        this.retardo_apagado = null
      } else {
        const apagado = JSON.parse(item.secuencia_variadores_apagado);

        // Obtengo todos los parámetros de apagado de todos los variadores de la secuencia recorriendo el array y creo un objeto con todos los parámetros encontrados
        apagado.forEach((parametro) => {
          const itemParametro = {

            retardo_apagado: parametro.retardo,
          };

          this.editedVariadoresInverse.push(itemParametro);
        });

      }

      this.editingItem = item;

      let fechaFormatted;
      if (typeof item.fecha === 'string') {
        fechaFormatted = item.fecha;
      } else {
        fechaFormatted = new Date(item.fecha).toISOString().split('T')[0];
      }

      let grupo_select = item.grupo.map(grupo => ({
        id: grupo.id_configuracion,
        duracion: grupo.duracion,
        nombre: grupo.nombre,
        fecha: fechaFormatted,
        hora_inicio: grupo.hora_inicio
      }));

      this.itemSecuencial = {
        id: item.id,
        nombre: item.titulo,
        minutos: item.duracion || 0,
        is_repetir: item.is_repetir === 1 ? 'si' : 'no',
        repetir_cada: item.repetir_cada,
        fecha: fechaFormatted,
        hora_inicio: item.hora_inicio,
      };

      // Agrego a editedSecuencia los elementos de grupo_select
      this.editedSecuencia = grupo_select.map((grupo, index) => ({
        id_configuracion: grupo.id,
        minutos: grupo.duracion,
        orden: index + 1,
        nombre: grupo.nombre,
        fecha: grupo.fecha,
        hora_inicio: grupo.hora_inicio
      }));

      // Deshabilito los grupos seleccionados
      grupo_select.forEach(grupo => {
        const index = this.grupos.findIndex(g => g.id === grupo.id);
        if (index !== -1) {
          this.$set(this.grupos, index, { ...this.grupos[index], disabled: true });
        }
      });


      const secuencia_arranque = JSON.parse(item.secuencia_variadores_arranque)
        .map((param_secuencia_arranque, index) => {
          const global_param = this.parametros.find(p => p.id === param_secuencia_arranque.id);
          return {
            id: param_secuencia_arranque.id,
            id_variador: global_param.id_variador,
            nombre_parametro: global_param.descripcion,
            retardo: param_secuencia_arranque.retardo,
            orden: index + 1
          }
        });

      this.editedVariadores = secuencia_arranque;

      const secuencia_apagado = JSON.parse(item.secuencia_variadores_apagado)
        .map((param_secuencia_apagado, index) => {
          const global_param = this.parametros.find(p => p.id === param_secuencia_apagado.id);
          return {
            id: param_secuencia_apagado.id,
            id_variador: global_param.id_variador,
            nombre_parametro: global_param.descripcion,
            retardo_apagado: param_secuencia_apagado.retardo,
            orden: index + 1
          }
        });

      this.editedVariadoresInverse = secuencia_apagado;

      this.is_param_added = true;
      this.dialog_secuencial = true;
    },

    getCoreData() {
      axios
        .post(process.env.VUE_APP_API_URL + "/mqtt/getCoreData", {
          id_dispositivo: localStorage.getItem("dispositivos"),
        })
        .then((res) => {
          // Asociamos las prograaaciones secuenciales con las programaciones que recogemos en data.info_programaciones.data[1]
          const programaciones_secuenciales_core_parametros = res.data.data.info_programaciones.parameter;
          const programaciones_secuenciales_core_datos = res.data.data.info_programaciones.data;
          // Buscamos el id del parametro con nombre id_secuencia y el parametro con nombre is_activa
          const id_secuencia_index = programaciones_secuenciales_core_parametros.findIndex(param => param === 'id_secuencia');
          const is_activa_index = programaciones_secuenciales_core_parametros.findIndex(param => param === 'is_activa');
          // Buscamos asociaciones entre programaciones secuenciales y programaciones y le actualizamos el parametro is_activa
          this.programacionesSecuenciales.forEach(secuencial => {
            const index = programaciones_secuenciales_core_datos.findIndex(programacion => programacion[id_secuencia_index] === secuencial.id);
            if (index !== -1) {
              secuencial.is_activa = res.data.data.info_programaciones.data[index][is_activa_index];
            }
          });
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  },
  computed: {
    formTitle() {
      if (this.dialog_secuencial === true && this.editingItem) {
        return 'Editar programación';
      } else if (this.dialog_secuencial === true) {
        return 'Nueva programación';
      } else {
        return 'Programación';
      }
    },

    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    nombreError() {
      if (this.showErrorForName) {
        return ["El nombre de la programación es requerido."];
      }
      if (this.nombreExists) {
        return [this.message_name_exist];
      }
      return [];
    },

    nombreExists() {
      const nombreTrimmed = this.itemSecuencial.nombre.trim().toLowerCase();
      return this.programacionesSecuenciales.some(programacion =>
        programacion.titulo.trim().toLowerCase() === nombreTrimmed &&
        programacion.id !== this.itemSecuencial.id
      );
    },

    addGrupoBtn() {
      const isFechaHoraRequerida = this.editedSecuencia.length === 0;
      const isFechaValida = this.itemSecuencial.fecha;
      const isHoraValida = this.itemSecuencial.hora_inicio;
      const isGrupoSeleccionado = this.gruposSelected.length > 0;
      const isMinutosSeleccionado = this.minutos_selected;

      if (isFechaHoraRequerida) {
        return !isFechaValida || !isHoraValida || !isGrupoSeleccionado || !isMinutosSeleccionado;
      } else {
        // Para los elementos subsiguientes, solo verifica grupo y minutos
        return !isGrupoSeleccionado || !isMinutosSeleccionado;
      }
    },

    addParamBtn() {
      const isParamSelected = this.param_selected.length > 0;
      const isRetardoSeleccionado = this.retardo_selected;
      return !isRetardoSeleccionado || !isParamSelected;
    },

    isDisabled() {
      return this.editedSecuencia.length > 0;
    },

  },

  beforeMount() {
    this.getProgramacionesSecuenciales();
    this.getProgramaciones();
    this.getParametros();

    // getCoreData cada 10 segundos
    this.intervalGetCoreData = setInterval(() => {
      this.getCoreData();
    }, 10000);
  },

  beforeDestroy() {
    clearInterval(this.intervalGetCoreData);
  },
};
</script>

<style scoped>
.select-pequeno .v-input__control {
  min-height: 32px;
  /* Ajusta esta altura según sea necesario */
  font-size: 12px;
  /* Ajusta el tamaño de la fuente según sea necesario */
}

.select-pequeno .v-select__selections {
  padding-top: 4px;
  padding-bottom: 4px;
}

.custom-height .v-input__control {
  height: 56px;
}

.mobile-large-btn {
  height: 56px;
  width: 100%;
}

.custom-height .v-input__control .v-field__append-inner {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 599px) {
  .mobile-large-btn {
    width: 100%;
    /* Esto hace que el botón ocupe todo el ancho del contenedor */
    height: 56px;
    /* Aumenta la altura del botón */

  }
}

.details {
  position: relative;
  overflow-y: auto;
  scrollbar-width: none;
}

.details::-webkit-scrollbar {
  width: 0px;
}

@media (min-width: 598px) {
  .details {
    z-index: 2;
    max-width: 780px;
    height: auto;
    margin: auto;
  }
}

@media (max-width: 599px) {
  .details {
    z-index: 6;
    width: 100%;
    height: 100vh;
    margin: 0;
  }
}

@media (max-width: 599px) {
  .v-stepper.v-stepper--vertical .v-stepper__header {
    flex-direction: column;
  }

  .v-stepper.v-stepper--vertical .v-stepper__step {
    display: block;
    width: 100%;
  }
}
</style>
<style>
:root {
  --status-indicator-size: 15px;
  --status-indicator-animation-duration: 2s;

  --status-indicator-color-positive: rgb(75, 210, 143);
  --status-indicator-color-positive-semi: rgba(75, 210, 143, .5);
  --status-indicator-color-positive-transparent: rgba(75, 210, 143, 0);
}

@keyframes status-indicator-pulse-positive {
  0% {
    box-shadow: 0 0 0 0 var(--status-indicator-color-positive-semi);
  }

  70% {
    box-shadow: 0 0 0 var(--status-indicator-size) var(--status-indicator-color-positive-transparent);
  }

  100% {
    box-shadow: 0 0 0 0 var(--status-indicator-color-positive-transparent);
  }
}

.status-indicator {
  display: inline-block;
  border-radius: 50%;
  width: var(--status-indicator-size);
  height: var(--status-indicator-size);
  background-color: var(--status-indicator-color-positive);
}

.status-indicator[pulse] {
  animation: status-indicator-pulse-positive var(--status-indicator-animation-duration) ease-in-out infinite;
}
</style>