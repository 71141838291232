<template>
	<v-card class="d-flex flex-column align-center justify-center avatars-instalaciones mx-2" width="200" height="250"
		elevation="4" @click="$emit('select', instalacion)" ripple color="teal darken-4">
		<v-avatar color="teal" size="90" class="d-flex justify-center align-center" style="cursor: pointer">
			<img v-if="instalacion.URL_avatar" :src="instalacion.URL_avatar" alt="Imagen seleccionada" />
			<span v-else style="color:white; font-size: 2rem;">{{ avatar }}</span>
		</v-avatar>
		<span class="nombre-instalacion mt-9">{{ instalacion.nombre }}</span>
		<div class="mt-2">
			<v-icon small color="white">mdi-map-marker-right</v-icon>
			<span class="ubicacion-instalacion mt-4 ml-2">
				{{ loadingUbicacion ? "Cargando ubicación..." : instalacion.ubicacion }}
			</span>
		</div>
	</v-card>
</template>

<script>
export default {
	props: {
		instalacion: {
			type: Object,
			required: true,
		},
		loadingUbicacion: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		avatar() {
			const palabras = this.instalacion.nombre.split(" ");
			return palabras.map((p) => p[0]).join("");
		},
	},
};
</script>

<style scoped>
.avatars-instalaciones {
	cursor: pointer;
	transition: transform 0.3s ease;
}

.avatars-instalaciones:hover {
	transform: scale(1.05);
}

.nombre-instalacion {
	color: white;
	font-size: 16px;
	margin-top: 10px;
	text-align: center;
	font-weight: 500;
}

.ubicacion-instalacion {
	color: white;
	font-size: 12px;
}
</style>