<template>
  <div>

    <!-- Widgets -->
    <v-row v-if="!$vuetify.breakpoint.mobile">
      <v-col cols="12" sm="3">
        <WeatherCard :lat="valvesLat" :lon="valvesLon" />
      </v-col>
      <v-col cols="12" sm="3">
        <ValvesCard :valves="connectedValves" />

      </v-col>
      <v-col cols="12" sm="3">
        <SensorCard :title="sensorWidget.title" :value="sensorWidget.value" :icon="sensorWidget.icon" />
      </v-col>
      <v-col cols="12" sm="3">
        <CoreCard />

      </v-col>
    </v-row>



    <!-- Si hay variadores -->
    <v-row :dense="$vuetify.breakpoint.mobile">
      <!-- Variadores -->
      <v-col cols="12" sm="4" v-if="showVariadoresDashboard">
        <v-card>
          <VariadoresDashboard :variadoresData="variadores" :valvula="valvula"
            :sensorSelectedOnMap="sensorSelectedOnMap" @getVariadores="getVariadores"
            @updateParametro="updateParametro" />
        </v-card>
      </v-col>

      <!-- Remotes -->
      <v-col cols="12" sm="8" v-if="showRemoteDashboard">
        <v-card>
          <RemoteDashboard :variadoresData="variadores" :remoteId="selectedRemoteId"
            :sensorSelectedOnMap="sensorSelectedOnMap" :key="key" :sensores="sensores" :valvula="valvula"
            :valvulas="electrovalvulas" @close-card="handleCloseCard" />
        </v-card>
      </v-col>
    </v-row>

    <!-- Si no hay variadores -->
    <v-row>
      <!-- Remote Dashboard sin variadores -->
      <v-col cols="12" sm="5" v-if="showRemoteDashboardWithoutVariadores">
        <v-card>
          <RemoteDashboard :variadoresData="variadores" :remoteId="selectedRemoteId"
            :sensorSelectedOnMap="sensorSelectedOnMap" :sensores="sensores" :valvula="valvula"
            :valvulas="electrovalvulas" @close-card="handleCloseCard" />
        </v-card>
      </v-col>

      <!-- Variadores Dashboard sin sensores -->
      <v-col cols="12" sm="5" v-if="showVariadoresDashboardWithoutSensores">
        <v-card>
          <VariadoresDashboard :variadoresData="variadores" :valvula="valvula"
            :sensorSelectedOnMap="sensorSelectedOnMap" @getVariadores="getVariadores"
            @updateParametro="updateParametro" />
        </v-card>
      </v-col>
    </v-row>

    <!-- Mapa -->
    <v-row>
      <v-col>
        <v-card :class="$vuetify.breakpoint.mobile ? 'mb-10' : ''">
          <v-card-text v-if="isSensoresLoaded" style="padding: 0px">
            <MapDashboard :valvulas="electrovalvulas" :sensores="sensores" @emitIdValve="selectedValve"
              @getElectrovalvulas="getElectrovalvulas" v-if="isSensoresLoaded && isValvulasLoaded" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Espaciado adicional para móviles -->
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-col></v-col>
    </v-row>
  </div>
</template>

<script>
import VariadoresDashboard from "@/components/VariadoresDashboard.vue";
import MapDashboard from "@/components/MapDashboard.vue";
import WeatherCard from "@/components/widgets/WeatherCard.vue";
import ValvesCard from "@/components/widgets/ValvesCard.vue";
import SensorCard from "@/components/widgets/SensorCard.vue";
import CoreCard from "@/components/widgets/CoreCard.vue";
import RemoteDashboard from "@/components/RemoteDashboard.vue";

import axios from "axios";


export default {
  components: {
    VariadoresDashboard,
    MapDashboard,
    RemoteDashboard,
    WeatherCard,
    ValvesCard,
    SensorCard,
    CoreCard
  },
  data() {
    return {
      variadores: null,
      sensorId: null,
      sensorSelectedOnMap: null,
      isSensoresLoaded: false,
      isValvulasLoaded: false,
      electrovalvulas: [],
      sensores: [],
      valvula: -1,
      isAuto: -1,
      battery: 100,
      key: 0,
      markers: [],
      electrovalvulaId: null,
      selectedRemoteId: null,
      idInterval: null,
      valvulasConectadas: 0,
      ultimoDatoHumedad: null,
      presionCabezal: null,

      valvesLat: 0,
      valvesLon: 0,
    };
  },
  computed: {
    // Computed properties para simplificar v-if
    showVariadoresDashboard() {
      return this.variadores && this.variadores.length > 0;
    },
    showRemoteDashboard() {
      return this.sensores && this.variadores && this.variadores.length > 0;
    },
    showRemoteDashboardWithoutVariadores() {
      return this.sensores && this.variadores && this.variadores.length === 0;
    },
    showVariadoresDashboardWithoutSensores() {
      return this.variadores && !this.sensores;
    },
    formattedUltimoDatoHumedad() {
      return this.ultimoDatoHumedad ? `${this.ultimoDatoHumedad.historico.values.slice(-1)[0].toFixed(0)}` : '-';
    },
    connectedValves() {
      return this.electrovalvulas.filter(valvula => valvula.current_state === 1).map(valvula => valvula.nombre);
    },
    sensorWidget() {

      // if (this.variadores && this.variadores.length > 0 && this.presionCabezal) {
      // Se obtiene el valor_actual, se verifica si es "error" o null, y se asigna '-' en esos casos
      let valorActual = this.presionCabezal;
      const presionCabezal = (valorActual !== "error" && valorActual !== null) ? valorActual : '-';
      if (presionCabezal !== '-') {
        return {
          title: 'Presión Cabezal',
          value: `${presionCabezal}`,
          icon: 'mdi-gauge',
        }
      } else if (this.ultimoDatoHumedad) {
        return {
          title: 'Humedad',
          value: this.formattedUltimoDatoHumedad,
          icon: 'mdi-water-percent',
        };
      } else {
        return {
          title: 'Presión Cabezal',
          value: '-',
          icon: 'mdi-gauge',
        };
      }
    }
  },

  methods: {
    getPresionVariador() {
      // Sacamos el primer variador que tenga la presión del cabezal y sus parametros
      const variador = this.variadores.find((variador) =>
        variador.parametros.some((parametro) => parametro.param === "presion_cabezal")
      );


      // variador tiene una propiedad parametros que es un array de objetos. Hay que encontrar el parametro con una propiedad llamada "param" y que su valor sea "presion_cabezal"
      const presionCabezal = variador.parametros.find(
        (parametro) => parametro.param === "presion_cabezal"
      );

      let bodyFormData = new FormData();
      bodyFormData.append("origin", "getPresionVariador"); // Se puede quitar, solo se puso para debug
      bodyFormData.append("function", "runOrderInInverter");
      bodyFormData.append("id_variador", variador.id);
      bodyFormData.append("id_param", presionCabezal.id);
      bodyFormData.append("is_write", "0");
      bodyFormData.append("value_to_write", "0");
      bodyFormData.append("is_read", "1");


      axios({
        url: process.env.VUE_APP_API_MQTT_URL,
        method: "POST",
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 20000,
      })
        .then((res) => {

          if (res.data) {

            const presion_leida = res.data.data[1]
            // Sustituimos 'x' por el valor de 'presion_leida'

            const formulaConValor = presionCabezal.conversion_front_end.replace("x", presion_leida);

            // Evaluamos la fórmula
            const resultado = eval(formulaConValor);

            this.presionCabezal = resultado.toFixed(1);

          } else {
            this.presionCabezal = '-';
          }
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });





    },

    //Función que se ejecuta cuando se presiona o pincha el botón de cerrar 
    handleCloseCard() {
      this.valvula = -1;
      this.sensorSelectedOnMap = null;
    },
    //Funcion que se ejecuta cuando se selecciona algun item del mapa, sea sensor o valvula
    selectedValve(selection) {
      if (selection.type === "valvula") {
        this.electrovalvulaId = selection.data.id;
        this.selectedRemoteId = selection.data.id_remote;
        this.valvula = this.electrovalvulas.find(
          (valvula) => valvula.id === selection.data.id
        );

      } else if (selection.type === "sensor") {
        this.sensorId = selection.data.id;
        this.selectedRemoteId = selection.data.id_remote;
        this.sensorSelectedOnMap = this.sensores.find(
          (sensor) => sensor.id === selection.data.id
        );
        // this.readSensorAndSaveData(selection.data.id);
      }
    },
    readSensorAndSaveData(id) {
      //Si hay un sensor y una valvula seleccionada se ejecuta la funcion
      if (this.valvula.id_remote != null && (this.sensorId != null || id)) {
        this.alertWait = true;
        let idSelected = id ?? this.sensorId;
        let bodyFormData = new FormData();
        bodyFormData.append("function", "readSensorAndSaveData");
        bodyFormData.append("id_remote", this.valvula.id_remote);
        bodyFormData.append("id_sensor", idSelected);

        axios({
          url: process.env.VUE_APP_API_MQTT_URL,
          method: "POST",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
    },
    //Actualizo el valor del parametro indicado
    updateParametro(id_variador, id, valor, tipo_valor) {
      if (tipo_valor != 'off,on') {
        valor = valor.toFixed(1);
      }

      this.variadores
        .find((variador) => variador.id === id_variador)
        .parametros.find((parametro) => parametro.id === id).valor_actual =
        valor;
    },
    //Obtenemos los sensores
    getSensores() {
      axios
        .post(process.env.VUE_APP_API_URL + "/getSensores/1", {
          id_dispositivo: localStorage.getItem("dispositivos"),
        })
        .then((res) => {
          this.sensores = res.data;

          // Obtenemos el último dato de humedad
          const sensorHumedad = this.sensores.find((sensor) => sensor.id_tipo_sensor === 16);
          if (sensorHumedad && sensorHumedad.historico && sensorHumedad.historico.values.length) {
            this.ultimoDatoHumedad = sensorHumedad;
          }
        })
        .finally(() => {
          this.isSensoresLoaded = true;
        });
    },
    // Obtenemos las electrovalvulas
    getElectrovalvulas() {
      axios
        .post(process.env.VUE_APP_API_URL + "/getElectrovalvulas", {
          id_dispositivo: localStorage.getItem("dispositivos"),
        })
        .then((res) => {
          this.electrovalvulas = res.data;
          // Cuenta las válvulas conectadas
          this.valvulasConectadas = this.electrovalvulas.filter((valvula) => valvula.current_state === 1).length;

          // Obtener la primera válvula con coordenadas y obtener el clima
          const valvulaConCoordenadas = this.electrovalvulas.find((valvula) => valvula.latitud && valvula.longitud);

          if (valvulaConCoordenadas) {
            this.valvesLat = valvulaConCoordenadas.latitud;
            this.valvesLon = valvulaConCoordenadas.longitud;
          }
        })
        .finally(() => {
          this.isValvulasLoaded = true;
        });
    },
    // Deprecated
    // // Obtenemos los variadores
    getVariadores() {
      axios
        .post(process.env.VUE_APP_API_URL + "/getVariadores", {
          id_dispositivo: localStorage.getItem("dispositivos"),
        })
        .then((res) => {
          this.variadores = res.data;
          this.getPresionVariador();
        })
        .finally(() => {
          this.isVariadoresLoaded = true;
        });
    },

    updateDataInterval() {
      if (localStorage.getItem("token") != null) {
        this.getElectrovalvulas();
        this.getSensores();
        // this.getVariadores();
      } else {
        //clear interval
        clearInterval(this.interval);
      }
    },


  },

  mounted() {

  },

  //Se hacen las llamadas antes de iniciar la vista
  async beforeMount() {
    await this.getVariadores();
    this.getSensores();
    this.getElectrovalvulas();

    //Se inicia el interval para ir actualizando la vista
    this.idInterval = setInterval(this.updateDataInterval, 5000);
  },
  //Se detiene el interval cuando se cierra la vista
  beforeRouteLeave(to, from, next) {
    clearInterval(this.idInterval);
    next();
  },
};
</script>

<style></style>
