<template>
	<div class="loading-overlay">
		<div class="logo-image-loading-container">
			<v-img :src="icon" class="logo-image-loading"></v-img>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		icon: {
			type: String,
			required: true,
		},
	},
};
</script>

<style scoped>
.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.logo-image-loading-container {
	width: 80px;
	height: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo-image-loading {
	max-width: 80%;
	max-height: 80%;
	opacity: 0.5;
	animation: fade-in-out 1s infinite;
}

@keyframes fade-in-out {

	0%,
	100% {
		opacity: 0.5;
	}

	50% {
		opacity: 1;
	}
}
</style>
