<template>
    <InstalacionComponent/>
  </template>
  
  <script>
  import InstalacionComponent from "@/components/InstalacionComponent.vue";
  export default {
  components: { InstalacionComponent },
  }
  </script>
  
  <style>
  
</style>