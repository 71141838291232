<template>
	<div class="card-container">
		<v-card class="info-card">
			<template v-if="isLoading">
				<div class="loading-container">
					<v-icon large color="grey darken-1" class="loading-spinner">mdi-loading</v-icon>
					<p>Cargando datos...</p>
				</div>
			</template>
			<template v-else>
				<v-btn icon x-small class="sync-button" @click.stop="handleSyncClick">
					<v-icon :class="{ 'rotate-icon': isSyncing }">mdi-sync-circle</v-icon>
				</v-btn>
				<div class="card-content" :class="{ flipped: flipped }">
					<div class="card-front card-face">
						<v-row align="center" no-gutters>
							<v-col :cols="5">
								<div class="icon-background">
									<v-icon xx-large :color="iconColor" class="icon-xx-large">
										{{ icon }}
									</v-icon>
								</div>
							</v-col>
							<v-col :cols="7">
								<div class="title-container">
									<div class="info-title">Previsión</div>
								</div>
								<div class="info-value">
									<span class="text-value">{{ weatherTemp }}</span>
									<span>ºC</span>
								</div>
							</v-col>
						</v-row>
					</div>
					<div class="card-back card-face">
						<div>
							<div class="center-content mb-1">
								<v-icon small color="red darken-2">mdi-map-marker-right</v-icon>
								<span class="small-text ml-1">{{ weatherLocation }}</span>
							</div>
							<div v-if="forecasts && forecasts.length" class="forecast-container">
								<div v-for="(forecast, index) in forecasts" :key="index" class="forecast-item mx-3">
									<div style="font-weight: 600; font-size: 12px">{{ forecast.day }}</div>
									<v-icon :color="forecast.iconColor" large>{{ forecast.icon }}</v-icon>
									<div style="font-weight: 400; font-size:16px">{{ forecast.minTemp.toFixed(0) }}°
										<strong>/</strong> {{
				forecast.temp.toFixed(0) }}°
									</div>
								</div>
							</div>
							<div v-else>
								No hay pronósticos
							</div>
						</div>

					</div>
				</div>
			</template>
		</v-card>
	</div>
</template>

<script>
import axios from "axios";
import { format, addDays } from "date-fns";
import { es } from "date-fns/locale";
export default {
	props: {
		lat: Number,
		lon: Number,
	},
	data() {
		return {
			flipped: false,
			isSyncing: false,
			isLoading: true,
			weatherTemp: "-",
			weatherHumidity: "-%",
			weatherAirQuality: "-m/s",
			weatherColor: "amber darken-1",
			icon: "",
			forecasts: [],
			weatherLocation: "-",
		};
	},
	watch: {
		lat: function (newVal) {
			this.getWeather(newVal, this.lon);
		},


	},
	mounted() {
		this.getWeather(this.lat, this.lon);
	},
	methods: {
		flipCard() {
			this.flipped = !this.flipped;
		},
		handleSyncClick() {
			this.isSyncing = true;
			this.flipCard();
			setTimeout(() => {
				this.isSyncing = false;
			}, 2000);
		},
		getWeather(lat, lon) {

			if (lat === undefined || lon === undefined || isNaN(lat) || isNaN(lon) || lat === 0 || lon === 0) {
				this.isLoading = true;
				return;
			}

			this.isLoading = true;

			// Sacamos la API key del Env
			const url = `https://api.weatherapi.com/v1/forecast.json?key=${process.env.VUE_APP_OPEN_WEATHER_API_KEY}&q=${lat},${lon}&days=4`;

			axios
				.get(url)
				.then((response) => {
					const current = response.data.current;
					const forecast = response.data.forecast.forecastday.slice(0, 3);
					const location = response.data.location;
					this.weatherLocation = `${location.name}`;

					if (
						current.temp_c !== undefined &&
						current.humidity !== undefined &&
						current.wind_kph !== undefined
					) {
						this.weatherTemp = `${current.temp_c.toFixed(0)}`;
						this.weatherHumidity = `${current.humidity}%`;
						this.weatherAirQuality = `${(current.wind_kph / 3.6).toFixed(2)} m/s`;
					} else {
						this.weatherTemp = null;
					}

					this.forecasts = forecast.map((day) => ({
						date: format(new Date(day.date), "EEE d"),
						temp: day.day.avgtemp_c,
						minTemp: day.day.mintemp_c,
						condition: day.day.condition.text,
						icon: this.getWeatherIconAndColor(day.day.condition.text).icon,
						iconColor: this.getWeatherIconAndColor(day.day.condition.text).color,
						day: this.capitalizeFirstLetter(
							format(addDays(new Date(day.date), 1), "EEEE d", { locale: es })
						),
					}));

					const weatherData = this.getWeatherIconAndColor(current.condition.text);
					this.icon = weatherData.icon;
					this.iconColor = weatherData.color;

					// Desactivar loader
					this.isLoading = false;
				})
				.catch((error) => {
					console.error("Error fetching weather data:", error);
				});
		},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		getWeatherIconAndColor(condition) {
			const conditionLower = condition.toLowerCase();

			if (conditionLower.includes("sunny")) {
				return { icon: "mdi-weather-sunny", color: "yellow darken-2" };
			} else if (conditionLower.includes("cloudy")) {
				return { icon: "mdi-weather-cloudy", color: "cyan lighten-4" };
			} else if (
				conditionLower.includes("rain") ||
				conditionLower.includes("shower") ||
				conditionLower.includes("drizzle")
			) {
				return { icon: "mdi-weather-rainy", color: "blue darken-4" };
			} else if (
				conditionLower.includes("thunder") ||
				conditionLower.includes("storm") ||
				conditionLower.includes("outbreak")
			) {
				if (
					conditionLower.includes("rain") ||
					conditionLower.includes("shower") ||
					conditionLower.includes("drizzle")
				) {
					return { icon: "mdi-weather-lightning-rainy", color: "blue-grey darken-1" };
				} else {
					return { icon: "mdi-weather-lightning", color: "blue-grey darken-1" };
				}
			} else if (
				conditionLower.includes("snow") ||
				conditionLower.includes("sleet") ||
				conditionLower.includes("flurries")
			) {
				return { icon: "mdi-weather-snowy-heavy", color: "cyan lighten-4" };
			} else if (
				conditionLower.includes("fog") ||
				conditionLower.includes("mist") ||
				conditionLower.includes("haze")
			) {
				return { icon: "mdi-weather-fog", color: "grey darken-1" };
			} else if (
				conditionLower.includes("patchy rain") ||
				conditionLower.includes("patchy drizzle")
			) {
				return { icon: "mdi-weather-partly-rainy", color: "blue lighten-2" };
			} else {
				// Default case
				return { icon: "mdi-weather-partly-cloudy", color: "amber darken-1" };
			}
		},
	},
};
</script>

<style scoped>
.center-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.small-text {
	font-size: 15px;
}

.sync-button {
	color: gray;
}

.sync-button:hover {
	color: #00acc1;
}

.text-right {
	text-align: right;
	margin-right: 10%;
}

.text-value {
	font-size: 55px;
	font-weight: 450;
}

.card-container {
	perspective: 1000px;
	width: 100%;
	position: relative;
}

.info-card {
	width: 100%;
	height: 145px;
	padding: 30px;
	background-color: white;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: box-shadow 0.3s ease-in-out;
	position: relative;
}

.card-content {
	width: 100%;
	height: 100%;
	transition: transform 0.6s;
	transform-style: preserve-3d;
	display: flex;
	align-items: center;
	justify-content: center;
}

.card-face {
	backface-visibility: hidden;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
}

.card-front {
	transform: rotateY(0deg);
}

.card-back {
	transform: rotateY(180deg);
	position: absolute;
	/* top: 0; */
	display: flex;
	align-items: center;
	justify-content: center;
}

.flipped {
	transform: rotateY(180deg);
}

.icon-xx-large {
	font-size: 70px;
	color: #00796d;
}

.icon-small {
	font-size: 60px;
}

.icon-background {
	background-color: #e6f0eb;
	border-radius: 50%;
	width: 110px;
	height: 110px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.title-container {
	text-align: right;
	height: 100%;
}

.info-title {
	margin-right: 12%;
	font-weight: 450;
	color: #333;
	font-size: 18px;
}

.align-title {
	margin-top: -20px;
}

.align-ecos-core {
	margin-top: -12px;
}

.info-value {
	font-size: 18px;
	margin-right: 10%;
	text-align: right;
}

.info-value-core {
	font-size: 16px;
	margin-right: 10%;
	text-align: right;
	margin-top: 10%;
}

.text-value-core {
	margin-left: 8px;
	font-size: 30px;
	font-weight: 450;
}

.forecast-container {
	display: flex;
	justify-content: space-around;
	width: 100%;
}

.forecast-item {
	text-align: center;
}

.icon-photo {
	width: 75px;
}

.chip-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.subtitle-1 {
	color: #666;
	font-size: 14px;
	text-align: center;
}

.sync-button {
	position: absolute;
	top: 8px;
	right: 8px;
}

.rotate-icon {
	animation: spin 0.6s linear;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

:root {
	--status-indicator-size: 15px;
	--status-indicator-animation-duration: 2s;

	--status-indicator-color-positive: rgb(75, 210, 143);
	--status-indicator-color-positive-semi: rgba(75, 210, 143, 0.5);
	--status-indicator-color-positive-transparent: rgba(75, 210, 143, 0);
}

@keyframes status-indicator-pulse-positive {
	0% {
		box-shadow: 0 0 0 0 var(--status-indicator-color-positive-semi);
	}

	70% {
		box-shadow: 0 0 0 var(--status-indicator-size) var(--status-indicator-color-positive-transparent);
	}

	100% {
		box-shadow: 0 0 0 0 var(--status-indicator-color-positive-transparent);
	}
}

.status-indicator {
	display: inline-block;
	border-radius: 50%;
	width: var(--status-indicator-size);
	height: var(--status-indicator-size);
	background-color: var(--status-indicator-color-positive);
}

.status-indicator[pulse] {
	animation: status-indicator-pulse-positive var(--status-indicator-animation-duration) ease-in-out infinite;
}

.loading-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
}

.loading-spinner {
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
