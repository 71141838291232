<template>
    <div class="card-container">
        <v-card class="info-card">
            <template v-if="isLoading">
                <div class="loading-container">
                    <v-icon large color="grey darken-1" class="loading-spinner">mdi-loading</v-icon>
                    <p>Cargando datos...</p>
                </div>
            </template>
            <template v-else>
                <v-btn icon x-small class="sync-button" @click.stop="handleSyncClick">
                    <v-icon :class="{ 'rotate-icon': isSyncing }">mdi-sync-circle</v-icon>
                </v-btn>
                <div class="card-content" :class="{ flipped: flipped }">
                    <div class="card-front card-face">
                        <v-row align="center" no-gutters>
                            <v-col cols="4">
                                <div class="icon-background">
                                    <img :src="core_icon" alt="Image" class="icon-photo " />
                                </div>
                            </v-col>
                            <v-col cols="8" class="align-ecos-core">
                                <div class="title-container">
                                    <div class="info-title">ECOS Core</div>
                                </div>
                                <div class="info-value-core">
                                    <span v-if="is_connect === 'Online'" class="status-indicator"
                                        style="background-color: teal;"></span>
                                    <span v-else class="status-indicator" style="background-color: gray;"></span>
                                    <span class="text-value-core">{{ is_connect }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="card-back card-face">

                        <div class="d-flex align-center justify-center flex-column">
                            <div class="subtitle-1" v-if="fechaUltimaConexion">
                                <div class="font-weight-medium pl-1">
                                    <v-icon>mdi-update</v-icon>
                                    Última conexión:
                                </div>
                                <div class="text-body-2 font-light-medium mt-3">
                                    {{ fechaUltimaConexion.replaceAll("-", "/").slice(0, -8).replace("T", " - ") }}
                                </div>
                            </div>
                            <div v-else class="pt-2 w-100 d-flex align-center justify-center flex-column"
                                style="height:120px">
                                <v-icon color="grey-darken-3" icon="mdi-alert-circle-outline" />
                                <div class="text-center w-75">
                                    <p class="text-grey-darken-3 text-caption">
                                        No existen datos de fecha de conexión
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </v-card>
    </div>
</template>

<script>
import core_icon from "@/assets/core-icon.png";
import axios from "axios";
export default {
    data() {
        return {
            core_icon,
            flipped: false,
            isSyncing: false,
            is_connect: '-',
            fechaUltimaConexion: '-',
        };
    },

    methods: {
        close(dialog) {
            this[dialog] = false;
        },
        flipCard() {
            this.flipped = !this.flipped;
        },
        handleSyncClick() {
            this.isSyncing = true;
            this.flipCard();
            setTimeout(() => {
                this.isSyncing = false;
            }, 2000);
        },
        getCoreData() {
            axios
                .post(process.env.VUE_APP_API_URL + "/mqtt/getCoreData", {
                    id_dispositivo: localStorage.getItem("dispositivos"),
                })
                .then((res) => {
                    // Si responde es que está conectado sino no
                    this.is_connect = res.status === 200 ? 'Online' : 'Offline';

                })
                .catch((error) => {
                    console.error("Error fetching data: ", error);
                    this.is_connect = 'Offline';
                });
        },
        getFechaUltimaConexion() {
            axios
                .post(process.env.VUE_APP_API_URL + "/getFechaConexionDispositivo", {
                    id_dispositivo: localStorage.getItem("dispositivos"),
                })
                .then((res) => {
                    this.fechaUltimaConexion = res.data[0].fecha_registro || '-';
                })
                .catch(() => {
                    this.fechaUltimaConexion = '-';
                });

        }
    },
    computed: {
        isLoading() {
            // Devuelve false cuando is_connect sea distinto de '-'
            return this.is_connect === '-';
        }
    },
    mounted() {
        this.getCoreData();
        this.getFechaUltimaConexion();

    }
};
</script>

<style scoped>
.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.small-text {
    font-size: 15px;
}

.sync-button {
    color: gray;
}

.sync-button:hover {
    color: #00acc1;
}

.text-right {
    text-align: right;
    margin-right: 10%;
}

.text-value {
    font-size: 55px;
    font-weight: 450;
}

.card-container {
    perspective: 1000px;
    width: 100%;
    position: relative;
}

.info-card {
    width: 100%;
    height: 145px;
    padding: 30px;
    background-color: white;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.3s ease-in-out;
    position: relative;
}

.card-content {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-face {
    backface-visibility: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.card-front {
    transform: rotateY(0deg);
}

.card-back {
    transform: rotateY(180deg);
    position: absolute;
    /* top: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.flipped {
    transform: rotateY(180deg);
}

.icon-xx-large {
    font-size: 70px;
    color: #00796d;
}

.icon-small {
    font-size: 60px;
}

.icon-background {
    background-color: #e6f0eb;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-container {
    text-align: right;
    height: 100%;
}

.info-title {
    margin-right: 12%;
    font-weight: 450;
    color: #333;
    font-size: 18px;
}

.align-title {
    margin-top: -20px;
}

.align-ecos-core {
    margin-top: -12px;
}

.info-value {
    font-size: 18px;
    margin-right: 10%;
    text-align: right;
}

.info-value-core {
    font-size: 16px;
    margin-right: 10%;
    text-align: right;
    margin-top: 10%
}

.text-value-core {
    margin-left: 8px;
    font-size: 30px;
    font-weight: 450;
}

.forecast-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.forecast-item {
    text-align: center;
}

.icon-photo {
    width: 75px;
}

.chip-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.subtitle-1 {
    color: #666;
    font-size: 14px;
    text-align: center;
}

.sync-button {
    position: absolute;
    top: 8px;
    right: 8px;
}

.rotate-icon {
    animation: spin 0.6s linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

:root {
    --status-indicator-size: 15px;
    --status-indicator-animation-duration: 2s;

    --status-indicator-color-positive: rgb(75, 210, 143);
    --status-indicator-color-positive-semi: rgba(75, 210, 143, .5);
    --status-indicator-color-positive-transparent: rgba(75, 210, 143, 0);
}

@keyframes status-indicator-pulse-positive {
    0% {
        box-shadow: 0 0 0 0 var(--status-indicator-color-positive-semi);
    }

    70% {
        box-shadow: 0 0 0 var(--status-indicator-size) var(--status-indicator-color-positive-transparent);
    }

    100% {
        box-shadow: 0 0 0 0 var(--status-indicator-color-positive-transparent);
    }
}

.status-indicator {
    display: inline-block;
    border-radius: 50%;
    width: var(--status-indicator-size);
    height: var(--status-indicator-size);
    background-color: var(--status-indicator-color-positive);
}

.status-indicator[pulse] {
    animation: status-indicator-pulse-positive var(--status-indicator-animation-duration) ease-in-out infinite;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
}

.loading-spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>