<template>
  <v-container class="d-flex justify-center align-center" style="height:80vh">
    <v-card class="teal darken-4 py-8" width="600">
      <v-container class="d-flex flex-column align-center justify-center">
        <figure>
          <v-img min-width="150px" max-width="200px" src="../assets/logoWhite.png"></v-img>
        </figure>
      </v-container>
      <v-form class="d-flex justify-center align-center flex-column" @submit.prevent="login">
        <v-text-field v-model="email" label="Email" dark :style="inputWidth" :rules="[emailError]" required>
        </v-text-field>
        <v-text-field v-model="password" :type="showPassword ? 'text' : 'password'" label="Password" dark
          :style="inputWidth" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="togglePasswordVisibility" :rules="[passwordError]" required>
        </v-text-field>
        <v-btn type="submit" dark class="mt-4">Iniciar Sesión</v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      emailError: true,
      passwordError: true,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async login() {
      try {
        const { data } = await axios.post(`${process.env.VUE_APP_API_URL}/login`, {
          email: this.email,
          password: this.password,
        });

        // Guardar datos del usuario en localStorage
        this.storeUserData(data);

        // Redirigir según el rol del usuario
        if (data.instalaciones && data.instalaciones.length > 0) {
          this.$router.push("/Instalacion");
        } else {
          this.$router.push("/Panel%20de%20control");
        }
      } catch (error) {
        this.emailError = "Email o contraseña incorrectos";
        this.passwordError = "Email o contraseña incorrectos";
      }
    },
    storeUserData(data) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("id", data.id);
      localStorage.setItem("email", data.email);
      localStorage.setItem("nombre", data.name);
      localStorage.setItem("apellido", data.last_name);
      localStorage.setItem("URL_avatar", data.URL_avatar || "");

      if (data.instalaciones && data.instalaciones.length > 0) {
        // Guardar instalaciones si el usuario es instalador
        localStorage.setItem("instalaciones", JSON.stringify(data.instalaciones));
      } else {
        // Guardar fincas si el usuario no es instalador
        localStorage.setItem("fincas", JSON.stringify(data.fincas));
        const finca = data.fincas[0];
        localStorage.setItem("dispositivos", finca.dispositivos.replace(/\s/g, ""));
        localStorage.setItem("id_finca", finca.id);
      }
    },
  },
  computed: {
    inputWidth() {
      return this.$vuetify.breakpoint.mobile ? "width:80%" : "width:70%";
    },
  },
};
</script>

<style></style>
