<template>
  <div>
    <!-- Loading overlay -->
    <LoadingOverlay v-if="loading" :icon="icon" />

    <!-- Logo superior -->
    <div class="logo-container" v-if="!loading" :style="logoStyle">
      <v-img :src="logoNav" class="logo-image" cover></v-img>
    </div>

    <!-- Título -->
    <v-card v-if="!loading" class="d-flex justify-center align-center" color="transparent" elevation="0">
      <p :class="tituloClass">Selecciona una instalación</p>
    </v-card>

    <!-- Contenedor de tarjetas -->
    <v-container v-if="!loading" class="d-flex justify-center align-center" style="min-height:50vh;">
      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-slide-group>
          <InstalacionCard v-for="(instalacion, index) in instalaciones" :key="index" :instalacion="instalacion"
            :loading-ubicacion="instalacion.cargandoUbicacion" @select="selectInstalacion" />
        </v-slide-group>
      </template>
      <template v-else>
        <v-row justify="center" align="center" no-gutters>
          <v-col v-for="(instalacion, index) in instalaciones" :key="index" cols="auto" class="text-center mx-2"
            style="margin-bottom: 20px;">
            <InstalacionCard :instalacion="instalacion" :loading-ubicacion="instalacion.cargandoUbicacion"
              @select="selectInstalacion" />
          </v-col>
        </v-row>
      </template>
    </v-container>

    <!-- Botón de cerrar sesión -->
    <div class="d-flex justify-center align-center" v-if="!loading">
      <v-btn @click="logout" x-large dark class="btn-exit">Cerrar sesión</v-btn>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoadingOverlay from "@/components/extras/LoadingOverlay.vue";
import InstalacionCard from "@/components/widgets/InstalacionCard.vue";
import icon from "@/assets/icon.png";
import logoNav from "@/assets/logo-nav.png";

export default {
  components: {
    LoadingOverlay,
    InstalacionCard,
  },
  data() {
    return {
      icon,
      logoNav,
      instalaciones: [],
      loading: true,
      id_usuario: localStorage.getItem("id"),
    };
  },
  computed: {
    logoStyle() {
      return this.$vuetify.breakpoint.smAndDown ? "width: 55%" : "width: 18%";
    },
    tituloClass() {
      return this.$vuetify.breakpoint.smAndDown ? "titulo mt-9" : "titulo";
    },
  },
  async mounted() {
    await this.getInstalaciones();
  },
  methods: {
    async getInstalaciones() {
      try {
        this.loading = true;

        // Obtener las instalaciones desde la API
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/getInstalaciones`, {
          id_usuario: this.id_usuario,
        });

        // Inicializar estado reactivo para cada instalación
        this.instalaciones = response.data.map((instalacion) => ({
          ...instalacion,
          ubicacion: "Cargando ubicación...", // Valor inicial
          cargandoUbicacion: true, // Indicador de carga de ubicación
        }));

        // Procesar ubicaciones de manera asíncrona
        this.instalaciones.forEach(async (instalacion) => {
          const finca = instalacion.fincas.find((f) => f.latitud !== 0 && f.longitud !== 0);
          if (finca) {
            try {
              const ubicacion = await this.getLocationNameByInstalationCoordinates(finca.latitud, finca.longitud);
              instalacion.ubicacion = ubicacion;
            } catch {
              instalacion.ubicacion = "Ubicación desconocida";
            } finally {
              instalacion.cargandoUbicacion = false; // Finaliza la carga de la ubicación
            }
          } else {
            instalacion.ubicacion = "Sin ubicación disponible";
            instalacion.cargandoUbicacion = false;
          }
        });

        this.loading = false;
      } catch (error) {
        console.error("Error al obtener instalaciones:", error);
        this.loading = false;
      }
    },
    async getLocationNameByInstalationCoordinates(lat, lon) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/getLocationNameByInstalationCoordinates`,
          { lat, lon }
        );
        return response.data.location_name;
      } catch (error) {
        console.error("Error al obtener ubicación:", error);
        throw error;
      }
    },
    selectInstalacion(instalacion) {
      this.loading = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}/getFincasByInstalacion`, { id_instalacion: instalacion.id })
        .then((response) => {
          const fincas = response.data.fincas;
          localStorage.setItem("fincas", JSON.stringify(fincas));

          const dispositivo_finca = fincas[0];
          localStorage.setItem("dispositivos", dispositivo_finca.dispositivos);
          localStorage.setItem("id_finca", dispositivo_finca.id);
          this.$router.push("/Panel%20de%20control");
        })
        .catch((error) => {
          console.error("Error al obtener fincas:", error);
          this.loading = false;
        });
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.titulo {
  font-size: 32px;
  text-align: center;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.logo-container {
  position: relative;
  margin-bottom: 20px;
}

.logo-image {
  width: 100%;
  height: auto;
  margin-left: 2%;
}

.btn-exit {
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.btn-exit:hover {
  background-color: #b71c1c;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.logo-image-loading-container {
  width: 80px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-image-loading {
  max-width: 80%;
  max-height: 80%;
  opacity: 0.5;
  animation: fade-in-out 1s infinite;
}

@keyframes fade-in-out {

  0%,
  100% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }
}

.v-row {
  margin: 0 !important;
}
</style>
